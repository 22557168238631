// src/components/InputSection/cards/LanguagesCard.js
import React from 'react';
import { Grid, TextField, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import SectionCardFactory from '../shared/SectionCardFactory';
import components from '../../../theme/components';

// Standard language proficiency levels - aligned with 5-level visualization
const PROFICIENCY_LEVELS = [
  { value: "Native / Bilingual", label: "Native / Bilingual (C2)" },
  { value: "Fluent", label: "Fluent (C1)" },
  { value: "Advanced", label: "Advanced (B2)" },
  { value: "Intermediate", label: "Intermediate (B1)" },
  { value: "Basic", label: "Basic (A2)" },
  { value: "Beginner", label: "Beginner (A1)" }
];

const LanguagesCard = SectionCardFactory({
  sectionName: "Languages",
  sectionKey: "languages",
  
  initialItemFields: () => ({
    language: '',
    proficiency: '',
    expanded: true,
  }),
  
  generateTitle: (item, index) => 
    item.language 
      ? `${item.language}${item.proficiency ? ` - ${item.proficiency}` : ''}`
      : `Language ${index + 1}`,
  
  renderFields: ({ item, handleChange }) => (
    <>
      <Grid item xs={12} sm={5}>
        <TextField
          fullWidth
          label="Language"
          value={item.language}
          onChange={(e) => handleChange('language', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <FormControl fullWidth sx={components.input.root}>
          <InputLabel id={`proficiency-label-${item.id || 'new'}`}>Proficiency</InputLabel>
          <Select
            labelId={`proficiency-label-${item.id || 'new'}`}
            value={item.proficiency}
            label="Proficiency"
            onChange={(e) => handleChange('proficiency', e.target.value)}
          >
            <MenuItem value=""><em>None</em></MenuItem>
            {PROFICIENCY_LEVELS.map((level) => (
              <MenuItem key={level.value} value={level.value}>{level.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  )
});

export default LanguagesCard;
