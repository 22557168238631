import React from 'react';
import { Box, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLaptopCode,
  faCode,
  faBriefcase,
  faGraduationCap,
  faCertificate,
  faAward,
  faLanguage,
  faHandsHelping,
  faFileAlt,
  faCodeBranch,
} from '@fortawesome/free-solid-svg-icons';

// Common utility functions that all layouts can use
export class BaseLayout {
  // Format date helper (adapted from BaseTemplate)
  static formatDate = (date) => {
    if (!date) return "";
    return new Date(date).toLocaleDateString("default", {
      month: "short",
      year: "numeric",
    });
  };

  // Helper function to render sub-items
  static renderSubItems = (items, renderFunction) => {
    if (!items || !Array.isArray(items)) return null;
    return items.map((item, index) => renderFunction(item, index));
  };

  // Safe render helper
  static safeRender(value) {
    if (value === null || value === undefined) {
      return '';
    }
    if (typeof value === 'object') {
      try {
        return JSON.stringify(value);
      } catch (e) {
        return '[Object]';
      }
    }
    return String(value);
  }

  // Section rendering functions (adapted from BaseTemplate)
  static renderProfessionalSummary(data, classes, colors) {
    if (!data?.professionalSummary) return null;
    const sectionColor = colors?.primary || '#333';

    return (
      <Box className={classes.section} data-section="Professional Summary">
        <Box className={classes.sectionHeader} style={{ borderBottomColor: sectionColor }}>
          <FontAwesomeIcon
            icon={faLaptopCode}
            className={classes.sectionIcon}
            style={{ color: sectionColor }}
          />
          <Typography
            className="sectionTitle"
            style={{ color: sectionColor }}
          >
            Professional Summary
          </Typography>
        </Box>
        <Box className={classes.sectionContent}>
          <div
            className={classes.richText}
            dangerouslySetInnerHTML={{ __html: data.professionalSummary }}
          />
        </Box>
      </Box>
    );
  }

  static renderWorkExperiences(data, classes, colors) {
    if (!data?.workExperiences || data.workExperiences.length === 0) return null;
    const sectionColor = colors?.secondary || '#333';

    return (
      <Box className={classes.section} data-section="Work Experience">
        <Box className={classes.sectionHeader} style={{ borderBottomColor: sectionColor }}>
          <FontAwesomeIcon
            icon={faBriefcase}
            className={classes.sectionIcon}
            style={{ color: sectionColor }}
          />
          <Typography
            className="sectionTitle"
            style={{ color: sectionColor }}
          >
            Work Experience
          </Typography>
        </Box>
        <Box className={classes.sectionContent}>
          {this.renderSubItems(data.workExperiences, (item) => (
            <Box key={item.id} className={classes.sectionItem}>
              <Typography className={classes.itemTitle}>
                {item.jobTitle}
              </Typography>
              <Typography className={classes.itemSubtitle}>
                {item.companyName}
                {item.location && ` | ${item.location}`}
              </Typography>
              <Typography className={classes.itemDate}>
                {this.formatDate(item.startDate)} - {item.current ? 'Present' : this.formatDate(item.endDate)}
              </Typography>
              <div className={classes.richText} dangerouslySetInnerHTML={{ __html: item.description }} />
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  static renderEducations(data, classes, colors) {
    if (!data?.educations || data.educations.length === 0) return null;
    const sectionColor = colors?.secondary || '#333';

    return (
      <Box className={classes.section} data-section="Education">
        <Box className={classes.sectionHeader} style={{ borderBottomColor: sectionColor }}>
          <FontAwesomeIcon
            icon={faGraduationCap}
            className={classes.sectionIcon}
            style={{ color: sectionColor }}
          />
          <Typography
            className="sectionTitle"
            style={{ color: sectionColor }}
          >
            Education
          </Typography>
        </Box>
        <Box className={classes.sectionContent}>
          {this.renderSubItems(data.educations, (item) => (
            <Box key={item.id} className={classes.sectionItem}>
              <Typography className={classes.itemTitle}>
                {item.degree}{item.fieldOfStudy ? `, ${item.fieldOfStudy}` : ''}
              </Typography>
              <Typography className={classes.itemSubtitle}>
                {item.schoolName}
                {item.location && ` | ${item.location}`}
              </Typography>
              <Typography className={classes.itemDate}>
                {this.formatDate(item.startDate)} - {item.current ? 'Present' : this.formatDate(item.endDate)}
              </Typography>
              <div className={classes.richText} dangerouslySetInnerHTML={{ __html: item.description }} />
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  static renderSkills(data, classes, colors) {
    if (!data?.skills || data.skills.length === 0) return null;
    const sectionColor = colors?.tertiary || '#333';

    return (
      <Box className={classes.section} data-section="Skills">
        <Box className={classes.sectionHeader} style={{ borderBottomColor: sectionColor }}>
          <FontAwesomeIcon
            icon={faCode}
            className={classes.sectionIcon}
            style={{ color: sectionColor }}
          />
          <Typography
            className="sectionTitle"
            style={{ color: sectionColor }}
          >
            Skills
          </Typography>
        </Box>
        <Box className={classes.sectionContent}>
          {this.renderSubItems(data.skills, (item) => (
            <Box key={item.id} className={classes.sectionItem}>
              <Typography className={classes.itemTitle}>
                {item.category}
              </Typography>
              <Typography className={classes.itemDescription}>
                {item.skills}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  static renderProjects(data, classes, colors) {
    if (!data?.projects || data.projects.length === 0) return null;
    const sectionColor = colors?.quaternary || '#333';

    return (
      <Box className={classes.section} data-section="Projects">
        <Box className={classes.sectionHeader} style={{ borderBottomColor: sectionColor }}>
          <FontAwesomeIcon
            icon={faCodeBranch}
            className={classes.sectionIcon}
            style={{ color: sectionColor }}
          />
          <Typography
            className="sectionTitle"
            style={{ color: sectionColor }}
          >
            Projects
          </Typography>
        </Box>
        <Box className={classes.sectionContent}>
          {this.renderSubItems(data.projects, (item) => (
            <Box key={item.id} className={classes.sectionItem}>
              <Typography className={classes.itemTitle}>
                {item.name}
                {item.technologies && ` - ${item.technologies}`}
              </Typography>
              <Typography className={classes.itemSubtitle}>
                {item.startDate && this.formatDate(item.startDate)}
                {item.endDate ? ` - ${this.formatDate(item.endDate)}` : item.current ? ' - Present' : ''}
              </Typography>
              <div className={classes.richText} dangerouslySetInnerHTML={{ __html: item.description }} />
              {item.link && (
                <a href={item.link} className={classes.itemLink} target="_blank" rel="noopener noreferrer">
                  {item.link}
                </a>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  static renderCertifications(data, classes, colors) {
    if (!data?.certifications || data.certifications.length === 0) return null;
    const sectionColor = colors?.tertiary || '#333';

    return (
      <Box className={classes.section} data-section="Certifications">
        <Box className={classes.sectionHeader} style={{ borderBottomColor: sectionColor }}>
          <FontAwesomeIcon
            icon={faCertificate}
            className={classes.sectionIcon}
            style={{ color: sectionColor }}
          />
          <Typography
            className="sectionTitle"
            style={{ color: sectionColor }}
          >
            Certifications
          </Typography>
        </Box>
        <Box className={classes.sectionContent}>
          {this.renderSubItems(data.certifications, (item) => (
            <Box key={item.id} className={classes.sectionItem}>
              <Typography className={classes.itemTitle}>
                {item.name}
              </Typography>
              <Typography className={classes.itemSubtitle}>
                {item.issuer}
              </Typography>
              <Typography className={classes.itemDate}>
                {this.formatDate(item.issueDate)}
                {!item.doesNotExpire && item.expirationDate && ` - ${this.formatDate(item.expirationDate)}`}
                {item.doesNotExpire && ' - No Expiration'}
              </Typography>
              <div className={classes.richText} dangerouslySetInnerHTML={{ __html: item.description }} />
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  static renderAwardsAndHonors(data, classes, colors) {
    if (!data?.awardsAndHonors || data.awardsAndHonors.length === 0) return null;
    const sectionColor = colors?.quaternary || '#333';

    return (
      <Box className={classes.section} data-section="Awards and Honors">
        <Box className={classes.sectionHeader} style={{ borderBottomColor: sectionColor }}>
          <FontAwesomeIcon
            icon={faAward}
            className={classes.sectionIcon}
            style={{ color: sectionColor }}
          />
          <Typography
            className="sectionTitle"
            style={{ color: sectionColor }}
          >
            Awards & Honors
          </Typography>
        </Box>
        <Box className={classes.sectionContent}>
          {this.renderSubItems(data.awardsAndHonors, (item) => (
            <Box key={item.id} className={classes.sectionItem}>
              <Typography className={classes.itemTitle}>
                {item.title}
                {item.issuer && ` - ${item.issuer}`}
              </Typography>
              <Typography className={classes.itemSubtitle}>
                {item.date && this.formatDate(item.date)}
              </Typography>
              <div className={classes.richText} dangerouslySetInnerHTML={{ __html: item.description }} />
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  static renderLanguages(data, classes, colors) {
    if (!data?.languages || data.languages.length === 0) return null;
    const sectionColor = colors?.tertiary || '#333';

    return (
      <Box className={classes.section} data-section="Languages">
        <Box className={classes.sectionHeader} style={{ borderBottomColor: sectionColor }}>
          <FontAwesomeIcon
            icon={faLanguage}
            className={classes.sectionIcon}
            style={{ color: sectionColor }}
          />
          <Typography
            className="sectionTitle"
            style={{ color: sectionColor }}
          >
            Languages
          </Typography>
        </Box>
        <Box className={classes.sectionContent}>
          {this.renderSubItems(data.languages, (item) => (
            <Box key={item.id} className={classes.sectionItem}>
              <Typography className={classes.itemTitle}>
                {item.language}
                {item.proficiency && ` - ${item.proficiency}`}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  static renderVolunteerExperiences(data, classes, colors) {
    if (!data?.volunteerExperiences || data.volunteerExperiences.length === 0) return null;
    const sectionColor = colors?.quaternary || '#333';

    return (
      <Box className={classes.section} data-section="Volunteer Experience">
        <Box className={classes.sectionHeader} style={{ borderBottomColor: sectionColor }}>
          <FontAwesomeIcon
            icon={faHandsHelping}
            className={classes.sectionIcon}
            style={{ color: sectionColor }}
          />
          <Typography
            className="sectionTitle"
            style={{ color: sectionColor }}
          >
            Volunteer Experience
          </Typography>
        </Box>
        <Box className={classes.sectionContent}>
          {this.renderSubItems(data.volunteerExperiences, (item) => (
            <Box key={item.id} className={classes.sectionItem}>
              <Typography className={classes.itemTitle}>
                {item.organization}
                {item.role && ` - ${item.role}`}
              </Typography>
              <Typography className={classes.itemSubtitle}>
                {(item.startDate || item.endDate) && ''}
                {item.startDate && this.formatDate(item.startDate)}
                {item.endDate ? ` - ${this.formatDate(item.endDate)}` : item.current ? ' - Present' : ''}
              </Typography>
              <div className={classes.richText} dangerouslySetInnerHTML={{ __html: item.description }} />
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  static renderPublications(data, classes, colors) {
    if (!data?.publications || data.publications.length === 0) return null;
    const sectionColor = colors?.quaternary || '#333';
    return (
      <Box className={classes.section} data-section="Publications">
        <Box className={classes.sectionHeader} style={{ borderBottomColor: sectionColor }}>
          <FontAwesomeIcon
            icon={faFileAlt}
            className={classes.sectionIcon}
            style={{ color: sectionColor }}
          />
          <Typography
            className="sectionTitle"
            style={{ color: sectionColor }}
          >
            Publications
          </Typography>
        </Box>
        <Box className={classes.sectionContent}>
          {this.renderSubItems(data.publications, (item) => (
            <Box key={item.id} className={classes.sectionItem}>
              <Typography className={classes.itemTitle}>
                {item.title}
              </Typography>
              <Typography className={classes.itemSubtitle}>
                {item.publisher}
                {item.date && ` | ${this.formatDate(item.date)}`}
              </Typography>
              <div className={classes.richText} dangerouslySetInnerHTML={{ __html: item.description }} />
              {item.url && (
                <a href={item.url} className={classes.itemLink} target="_blank" rel="noopener noreferrer">
                  {item.url}
                </a>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  static renderReferences(data, classes, colors) {
    if (!data?.references || data.references.length === 0) return null;
    const sectionColor = colors?.quaternary || '#333';
    return (
      <Box className={classes.section} data-section="References">
        <Box className={classes.sectionHeader} style={{ borderBottomColor: sectionColor }}>
          <FontAwesomeIcon
            icon={faFileAlt}
            className={classes.sectionIcon}
            style={{ color: sectionColor }}
          />
          <Typography
            className="sectionTitle"
            style={{ color: sectionColor }}
          >
            References
          </Typography>
        </Box>
        <Box className={classes.sectionContent}>
          {this.renderSubItems(data.references, (item) => (
            <Box key={item.id} className={classes.sectionItem}>
              <Typography className={classes.itemTitle}>
                {item.name}
                {item.position && ` - ${item.position}`}
              </Typography>
              <Typography className={classes.itemSubtitle}>
                {item.company}
              </Typography>
              {item.email && (
                <Typography className={classes.itemDescription}>
                  Email: {item.email}
                </Typography>
              )}
              {item.phone && (
                <Typography className={classes.itemDescription}>
                  Phone: {item.phone}
                </Typography>
              )}
              {item.reference && (
                <div className={classes.richText} dangerouslySetInnerHTML={{ __html: item.reference }} />
              )}
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  // Add this new method to the BaseLayout class
  static getTypographyKey(typographySettings) {
    return `typography-${typographySettings?.body?.fontSize}-${typographySettings?.body?.fontFamily}-${typographySettings?.globalLineHeight || typographySettings?.body?.lineHeight}`;
  }

  // Common helper to apply typography settings
  static getTypographyStyles(typographySettings) {
    if (!typographySettings) return {};

    return {
      // Base font settings
      fontFamily: typographySettings?.body?.fontFamily || 'Arial, sans-serif',
      fontSize: `${typographySettings?.globalFontSize || 14}px`,
      lineHeight: typographySettings?.globalLineHeight || 1.5,

      // Resume name at the top
      '& .headerName': {
        fontSize: `${typographySettings?.heading?.fontSize || 28}px`,
        fontFamily: typographySettings?.heading?.fontFamily || 'Arial, sans-serif',
        lineHeight: `${typographySettings?.heading?.lineHeight || 1.4}`,
        fontWeight: 'bold',
      },

      // Professional title at the top
      '& .headerTitle': {
        fontSize: `${typographySettings?.subheading?.fontSize || 20}px`,
        fontFamily: typographySettings?.subheading?.fontFamily || 'Arial, sans-serif',
        lineHeight: `${typographySettings?.subheading?.lineHeight || 1.3}`,
      },

      // Main section headers (Work Experience, Education, etc.)
      '& .sectionTitle': {
        fontSize: `${typographySettings?.subheading?.fontSize || 18}px`,
        fontFamily: typographySettings?.subheading?.fontFamily || 'Arial, sans-serif',
        lineHeight: `${typographySettings?.subheading?.lineHeight || 1.3}`,
        fontWeight: 'bold',
      },

      // Job titles, degree names, project titles, etc.
      '& .itemTitle': {
        fontSize: `${typographySettings?.subheading?.fontSize || 20}px`,
        fontFamily: typographySettings?.subheading?.fontFamily || 'Arial, sans-serif',
        lineHeight: `${typographySettings?.subheading?.lineHeight || 1.3}`,
        fontWeight: 'bold',
      },

      // Companies, schools, item subtitles
      '& .itemSubtitle': {
        fontSize: `${typographySettings?.body?.fontSize || 14}px`,
        fontFamily: typographySettings?.body?.fontFamily || 'Arial, sans-serif',
        lineHeight: `${typographySettings?.body?.lineHeight || 1.5}`,
      },

      // Body text and descriptions
      '& .itemDescription, & .paragraph, & .richText': {
        fontSize: `${typographySettings?.body?.fontSize || 14}px`,
        fontFamily: typographySettings?.body?.fontFamily || 'Arial, sans-serif',
        lineHeight: `${typographySettings?.body?.lineHeight || 1.5}`,

        '& ul, & ol': {
          fontSize: `${typographySettings?.body?.fontSize || 14}px`,
          lineHeight: `${typographySettings?.body?.lineHeight || 1.5}`,
        },
        '& li': {
          fontSize: `${typographySettings?.body?.fontSize || 14}px`,
          lineHeight: `${typographySettings?.body?.lineHeight || 1.5}`,
        },
        '& p': {
          fontSize: `${typographySettings?.body?.fontSize || 14}px`,
          lineHeight: `${typographySettings?.body?.lineHeight || 1.5}`,
          margin: '6px 0',
        }
      },

      // Dates, locations, and other small details
      '& .smallText, & .contactItem, & .itemDate': {
        fontSize: `${typographySettings?.small?.fontSize || 12}px`,
        fontFamily: typographySettings?.small?.fontFamily || 'Arial, sans-serif',
        lineHeight: `${typographySettings?.small?.lineHeight || 1.2}`,
      },

      // Sidebar specific typography
      '& .sidebarSectionTitle': {
        fontSize: `${Math.max((typographySettings?.heading?.fontSize || 28) - 8, 16)}px`,
        fontFamily: typographySettings?.heading?.fontFamily || 'Arial, sans-serif',
        lineHeight: `${typographySettings?.heading?.lineHeight || 1.4}`,
        fontWeight: 'bold',
      },

      '& .sidebarItemTitle': {
        fontSize: `${Math.max((typographySettings?.subheading?.fontSize || 20) - 4, 14)}px`,
        fontFamily: typographySettings?.subheading?.fontFamily || 'Arial, sans-serif',
        lineHeight: `${typographySettings?.subheading?.lineHeight || 1.3}`,
        fontWeight: 'bold',
      },

      '& .sidebarItemSubtitle': {
        fontSize: `${typographySettings?.small?.fontSize || 12}px`,
        fontFamily: typographySettings?.small?.fontFamily || 'Arial, sans-serif',
      },

    };
  }
}

export default BaseLayout; 