import React, { useContext } from 'react';
import { Box, IconButton, TextField, Tooltip, Divider, Typography, useTheme } from '@mui/material';
import { Add, Remove, RestartAlt, NavigateNext, NavigateBefore } from '@mui/icons-material';
import { PaginationContext } from '../../context/PaginationContext';
import { ThemeContext } from '../../context/ThemeContext';

// Set universal minimum scale
const MIN_SCALE = 0.2;
const MAX_SCALE = 1.5;

function PageControls({ scale, setScale, position, setPosition, isNarrowScreen, defaultScale = 0.6 }) {
  const { pages, currentPage, setCurrentPage } = useContext(PaginationContext);
  const { isDarkMode } = useContext(ThemeContext);
  const theme = useTheme();

  const handleReset = () => {
    // Reset to the provided default scale (40% on mobile, 60% on desktop)
    setScale(defaultScale);
    setPosition({ x: 0, y: 0 });
  };

  const handleZoomInput = (e) => {
    const value = parseFloat(e.target.value) / 100;
    if (!isNaN(value) && value >= MIN_SCALE && value <= MAX_SCALE) {
      setScale(value);
      if (value === defaultScale) {
        setPosition({ x: 0, y: 0 });
      }
    }
  };

  const adjustZoom = (delta) => {
    const newScale = Math.min(MAX_SCALE, Math.max(MIN_SCALE, scale + delta));
    setScale(newScale);
    if (newScale === defaultScale) {
      setPosition({ x: 0, y: 0 });
    }
  };

  const handlePrevPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 0));
  };

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, pages.length - 1));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isNarrowScreen ? 'row' : 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 0.5,
        position: isNarrowScreen ? 'absolute' : 'fixed',
        ...(isNarrowScreen 
          ? { 
              bottom: '9%',
              left: '50%', 
              transform: 'translateX(-50%)',
              flexWrap: 'wrap',
              maxWidth: '90%',
              zIndex: 1000,
              backgroundColor: theme.palette.mode === 'dark'
                ? 'rgba(30, 30, 30, 0.95)'
                : 'rgba(255, 255, 255, 0.9)',
              backdropFilter: 'blur(4px)',
              padding: '8px 10px',
              borderRadius: '8px',
              boxShadow: theme.palette.mode === 'dark'
                ? '0 4px 12px rgba(255,255,255,0.25)'
                : '0 4px 12px rgba(0,0,0,0.2)',
              border: `2px solid ${theme.palette.mode === 'dark' 
                ? 'rgba(255,255,255,0.15)' 
                : 'rgba(0,0,0,0.1)'}`,
            } 
          : { 
              left: '10px',
              top: '50%', 
              transform: 'translateY(-50%)',
              position: 'absolute',
            }
        ),
        opacity: isNarrowScreen 
          ? 1
          : (theme.palette.mode === 'dark' ? 0.85 : 0.7),
        '&:hover': { opacity: 1 },
        transition: 'opacity 0.2s',
        backgroundColor: theme.palette.mode === 'dark'
          ? 'rgba(50, 50, 50, 0.95)'
          : 'white',
        borderRadius: '4px',
        padding: '4px',
        boxShadow: theme.palette.mode === 'dark'
          ? '0 0 5px rgba(255,255,255,0.2)'
          : '0 0 5px rgba(0,0,0,0.05)',
        zIndex: isNarrowScreen ? 1000 : 1,
        border: theme.palette.mode === 'dark' 
          ? '1px solid rgba(255,255,255,0.1)' 
          : 'none',
      }}
    >
      {/* Zoom Controls */}
      <Tooltip title="Zoom In" placement={isNarrowScreen ? "top" : "left"}>
        <IconButton
          size="small"
          onClick={() => adjustZoom(0.1)}
          disabled={scale >= MAX_SCALE}
          sx={{ 
            padding: '0px', 
            height: '28px',
            color: theme.palette.mode === 'dark' 
              ? 'rgba(255,255,255,0.9)'
              : undefined
          }}
        >
          <Add fontSize="small" />
        </IconButton>
      </Tooltip>
      <TextField
        size="small"
        value={`${Math.round(scale * 100)}%`}
        onChange={handleZoomInput}
        inputProps={{
          style: {
            padding: '2px',
            width: '32px',
            textAlign: 'center',
            fontSize: '12px',
            color: theme.palette.mode === 'dark' 
              ? 'rgba(255,255,255,0.9)' 
              : '#000',
            height: '20px',
            lineHeight: '20px',
          }
        }}
        sx={{
          my: '4px',
          '& .MuiOutlinedInput-root': {
            height: '28px',
            '& fieldset': {
              borderColor: theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,0.2)'
                : 'transparent',
            },
            '&:hover fieldset': {
              borderColor: theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,0.3)'
                : 'rgba(0,0,0,0.1)',
            },
            backgroundColor: theme.palette.mode === 'dark'
              ? 'rgba(255,255,255,0.1)'
              : 'transparent',
          },
        }}
      />
      <Tooltip title="Zoom Out" placement={isNarrowScreen ? "top" : "left"}>
        <IconButton
          size="small"
          onClick={() => adjustZoom(-0.1)}
          disabled={scale <= MIN_SCALE}
          sx={{ 
            padding: '0px', 
            height: '28px', 
            mt: '-4px',
            color: theme.palette.mode === 'dark' 
              ? 'rgba(255,255,255,0.9)'
              : undefined
          }}
        >
          <Remove fontSize="small" />
        </IconButton>
      </Tooltip>

      <Tooltip title="Reset View" placement={isNarrowScreen ? "top" : "left"}>
        <IconButton
          size="small"
          onClick={handleReset}
          disabled={scale === defaultScale && position.x === 0 && position.y === 0}
          sx={{ 
            padding: '2px',
            color: theme.palette.mode === 'dark' 
              ? 'rgba(255,255,255,0.9)'
              : undefined
          }}
        >
          <RestartAlt fontSize="small" />
        </IconButton>
      </Tooltip>

      {/* Page Navigation Controls */}
      {pages.length > 1 && (
        <>
          <Tooltip title="Previous Page" placement="left">
            <span>
              <IconButton
                size="small"
                onClick={handlePrevPage}
                disabled={currentPage === 0}
                sx={{ 
                  padding: '2px',
                  color: theme.palette.mode === 'dark' && currentPage !== 0
                    ? 'rgba(255,255,255,0.9)'
                    : undefined
                }}
              >
                <NavigateBefore fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
          <Typography variant="caption" sx={{ 
            fontSize: '10px', 
            opacity: theme.palette.mode === 'dark' ? 0.9 : 0.7,
            textAlign: 'center', 
            whiteSpace: 'nowrap',
            color: theme.palette.mode === 'dark' 
              ? 'rgba(255,255,255,0.9)'
              : undefined
          }}>
            {currentPage + 1} / {pages.length}
          </Typography>
          <Tooltip title="Next Page" placement="left">
            <span>
              <IconButton
                size="small"
                onClick={handleNextPage}
                disabled={currentPage === pages.length - 1}
                sx={{ 
                  padding: '2px',
                  color: theme.palette.mode === 'dark' && currentPage !== pages.length - 1
                    ? 'rgba(255,255,255,0.9)'
                    : undefined
                }}
              >
                <NavigateNext fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        </>
      )}
    </Box>
  );
}

export default PageControls; 