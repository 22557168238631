import React, { createContext, useState, useContext, useEffect } from 'react';
import { getAllLayouts } from '../layouts/LayoutRegistry';

// Create context
const LayoutContext = createContext();

export function LayoutProvider({ children, initialLayout }) {
  // Get layouts from registry
  const layouts = getAllLayouts().reduce((acc, layout) => {
    acc[layout.id] = layout;
    return acc;
  }, {});
  
  // Default to centered header layout or load from localStorage or prop
  const [selectedLayout, setSelectedLayout] = useState(() => {
    if (initialLayout) return initialLayout;
    
    try {
      const savedLayout = localStorage.getItem('selectedLayout');
      return savedLayout && layouts[savedLayout] ? savedLayout : 'centered-header';
    } catch (error) {
      console.error('Failed to load layout settings:', error);
      return 'centered-header';
    }
  });

  // Save layout to localStorage when changed
  useEffect(() => {
    try {
      localStorage.setItem('selectedLayout', selectedLayout);
    } catch (error) {
      console.error('Failed to save layout settings:', error);
    }
  }, [selectedLayout]);

  return (
    <LayoutContext.Provider 
      value={{
        layouts,
        selectedLayout,
        setSelectedLayout
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

export function useLayout() {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayout must be used within a LayoutProvider');
  }
  return context;
}

export default { LayoutContext, LayoutProvider, useLayout }; 