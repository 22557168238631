// Gradient design based on Instagram's colors
const GradientDesign = {
  id: 'gradient',
  name: 'Social Tech',
  description: 'Modern design with stylish gradient accents',
  colors: {
    primary: "#C13584",    // Instagram Magenta/Pink
    secondary: "#833AB4",  // Instagram Purple
    tertiary: "#5851DB",   // Instagram Blue-Purple
    quaternary: "#405DE6", // Instagram Blue
    accent1: "#FD1D1D",    // Instagram Red/Orange
    accent2: "#F56040",    // Instagram Orange
    accent3: "#FCAF45",    // Instagram Yellow
    accent4: "#FFDC80",    // Instagram Light Yellow
    accent5: "#E1306C",    // Instagram Deep Pink
    accent6: "#3897F0",    // Instagram Light Blue
    accent7: "#8A3AB9",    // Instagram Medium Purple
    accent8: "#BC2A8D",    // Instagram Rose
    text: "#262626",       // Instagram Dark Text
    textSecondary: "#8E8E8E", // Instagram Secondary Text
    background: "#FFFFFF", // White background
    sidebarBackground: "#FAFAFA", // Instagram Light Gray background
    border: "#DBDBDB",     // Instagram border color
  },
  typography: {
    heading: {
      fontSize: 28,
      fontFamily: "'SF Pro Display', 'Segoe UI', sans-serif",
      lineHeight: 1.3,
    },
    subheading: {
      fontSize: 18,
      fontFamily: "'SF Pro Display', 'Segoe UI', sans-serif",
    },
    body: {
      fontSize: 14,
      fontFamily: "'SF Pro Text', 'Segoe UI', sans-serif",
    },
    small: {
      fontSize: 12,
      fontFamily: "'SF Pro Text', 'Segoe UI', sans-serif",
    },
    globalFontSize: 14,
    globalLineHeight: 1.5,
  }
};

export default GradientDesign; 