import { Global, css } from '@emotion/react';
import React from 'react';

const GlobalStyles = () => (
  <Global
    styles={css`
      * {
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE and Edge */
      }

      *::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
        background: transparent;
      }
    `}
  />
);

export default GlobalStyles; 