import React, { useEffect } from 'react';
import { Box, Typography, Avatar, Divider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faAt,
  faMapMarkerAlt,
  faCode,
  faLanguage,
  faCertificate
} from '@fortawesome/free-solid-svg-icons';
import {
  faGithub,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { makeStyles } from '@mui/styles';
import { BaseLayout } from './BaseLayout';

// Create the layout component with a right sidebar
const SidebarRightLayout = ({
  formData,
  selectedSections,
  design,
  typographySettings,
  currentPage = 0,
  totalPages = 1,
  measureMode = false,
  isPdfExport = false,
  onRenderComplete,
}) => {
  // Call onRenderComplete once, after first mount
  useEffect(() => {
    if (typeof onRenderComplete === 'function') {
      onRenderComplete();
    }
  }, [onRenderComplete]);

  // Define sections that should go in sidebar
  const sidebarSectionNames = ['Skills', 'Languages', 'Certifications'];

  // Create styles for this layout - modified from SidebarLeftLayout
  const useStyles = makeStyles({
    container: {
      width: '210mm',
      minHeight: '297mm',
      padding: 0,
      boxSizing: 'border-box',
      backgroundColor: design?.colors?.background || '#ffffff',
      color: design?.colors?.text || '#000000',
      display: 'flex',
      ...BaseLayout.getTypographyStyles(typographySettings),
    },
    sidebar: {
      width: '70mm',
      minHeight: '297mm',
      backgroundColor: design?.colors?.sidebarBackground || '#f5f5f5',
      padding: '15mm 10mm',
      boxSizing: 'border-box',
      borderLeft: `1px solid ${design?.colors?.border || '#ddd'}`,
    },
    mainContent: {
      width: '140mm',
      minHeight: '297mm',
      padding: '15mm 15mm 15mm 10mm',
      boxSizing: 'border-box',
    },
    profilePhoto: {
      width: '100px',
      height: '100px',
      margin: '0 auto 15px',
      border: `3px solid ${design?.colors?.primary || '#000000'}`,
    },
    headerName: {
      fontSize: typographySettings?.heading?.fontSize || 28,
      fontFamily: typographySettings?.heading?.fontFamily || 'Arial, sans-serif',
      fontWeight: 'bold',
      color: design?.colors?.primary || '#000000',
      textAlign: 'center',
    },
    headerTitle: {
      fontSize: typographySettings?.subheading?.fontSize || 20,
      fontFamily: typographySettings?.subheading?.fontFamily || 'Arial, sans-serif',
      color: design?.colors?.secondary || '#333333',
      marginTop: '5px',
      textAlign: 'center',
    },
    contactSection: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      marginTop: '20px',
    },
    contactItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      fontSize: typographySettings?.small?.fontSize || 12,
      color: design?.colors?.text || '#000000',
    },
    contactIcon: {
      color: design?.colors?.primary || '#000000',
      fontSize: '16px',
      width: '16px',
      minWidth: '16px',
    },
    sidebarSection: {
      marginTop: '25px',
    },
    sidebarSectionTitle: {
      color: design?.colors?.primary || '#000000',
      borderBottom: `2px solid transparent`,
      paddingBottom: '5px',
      marginBottom: '10px',
    },
    sidebarItem: {
      marginBottom: '8px',
    },
    sidebarItemTitle: {
      fontSize: `${typographySettings?.body?.fontSize || 11}px`,
      fontFamily: typographySettings?.body?.fontFamily || 'Arial, sans-serif',
      fontWeight: 'bold',
      color: design?.colors?.text || '#000000',
      lineHeight: `${typographySettings?.body?.lineHeight || 1.5}`,
    },
    sidebarItemSubtitle: {
      fontSize: `${typographySettings?.small?.fontSize || 10}px`,
      fontFamily: typographySettings?.small?.fontFamily || 'Arial, sans-serif',
      color: design?.colors?.textSecondary || '#666666',
      lineHeight: `${typographySettings?.small?.lineHeight || 1.3}`,
    },
    // Section styles for main content
    section: {
      marginBottom: '20px',
      pageBreakInside: 'avoid',
      breakInside: 'avoid',
    },
    sectionHeader: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      borderBottom: `2px solid transparent`,
      paddingBottom: '5px',
    },
    sectionIcon: {
      marginRight: '8px',
      color: design?.colors?.primary || '#000000',
    },
    sectionTitle: {
      color: design?.colors?.primary || '#000000',
    },
    sectionContent: {
      marginLeft: '25px',
    },
    sectionItem: {
      marginBottom: '16px',
    },
    itemTitle: {
      fontSize: `${typographySettings?.body?.fontSize + 2 || 14}px`,
      fontFamily: typographySettings?.body?.fontFamily || 'Arial, sans-serif',
      fontWeight: 'bold',
      color: design?.colors?.text || '#000000',
      lineHeight: `${typographySettings?.body?.lineHeight || 1.5}`,
    },
    itemSubtitle: {
      fontSize: `${typographySettings?.body?.fontSize || 11}px`,
      fontFamily: typographySettings?.body?.fontFamily || 'Arial, sans-serif',
      color: design?.colors?.textSecondary || '#666666',
      marginTop: '2px',
      lineHeight: `${typographySettings?.body?.lineHeight || 1.5}`,
    },
    itemDescription: {
      fontSize: `${typographySettings?.body?.fontSize || 11}px`,
      fontFamily: typographySettings?.body?.fontFamily || 'Arial, sans-serif',
      color: design?.colors?.text || '#000000',
      marginTop: '4px',
      lineHeight: `${typographySettings?.body?.lineHeight || 1.5}`,
    },
    richText: {
      marginTop: '8px',
      fontSize: `${typographySettings?.body?.fontSize || 11}px`,
      fontFamily: typographySettings?.body?.fontFamily || 'Arial, sans-serif',
      lineHeight: `${typographySettings?.body?.lineHeight || 1.5}`,
      "& ul, & ol": {
        margin: "6px 0",
        paddingLeft: "20px",
        fontSize: `${typographySettings?.body?.fontSize || 11}px`,
        lineHeight: `${typographySettings?.body?.lineHeight || 1.5}`,
      },
      "& li": {
        marginBottom: "4px",
        fontSize: `${typographySettings?.body?.fontSize || 11}px`,
        lineHeight: `${typographySettings?.body?.lineHeight || 1.5}`,
      },
      "& p": {
        margin: "6px 0",
        fontSize: `${typographySettings?.body?.fontSize || 11}px`,
        lineHeight: `${typographySettings?.body?.lineHeight || 1.5}`,
      }
    },
    itemLink: {
      color: design?.colors?.primary || '#0000FF',
      display: 'block',
      marginTop: '5px',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    paragraph: {
      fontSize: `${typographySettings?.body?.fontSize || 11}px`,
      fontFamily: typographySettings?.body?.fontFamily || 'Arial, sans-serif',
      lineHeight: `${typographySettings?.body?.lineHeight || 1.5}`,
    },
    skillCategory: {
      marginBottom: '10px',
    },
    // Special styling for sidebar sections
    languageLevel: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      marginTop: '5px',
    },
    languageDot: {
      width: '8px',
      height: '8px',
      borderRadius: '50%',
    },
    languageDotFilled: {
      backgroundColor: design?.colors?.primary || '#000000',
    },
    languageDotEmpty: {
      backgroundColor: design?.colors?.border || '#ddd',
      border: `1px solid ${design?.colors?.border || '#ddd'}`,
    },
    smallText: {
      fontSize: `${typographySettings?.small?.fontSize || 10}px`,
      fontFamily: typographySettings?.small?.fontFamily || 'Arial, sans-serif',
      color: design?.colors?.textSecondary || '#666666',
      lineHeight: `${typographySettings?.small?.lineHeight || 1.3}`,
    },
  });
  
  const classes = useStyles();

  // Get personal info from form data
  const { personalInfo = {} } = formData;

  // Helper to get proficiency level
  const getProficiencyLevel = (proficiency) => {
    switch (proficiency) {
      case "Native / Bilingual":
        return 5;
      case "Fluent":
        return 4;
      case "Advanced":
        return 3;
      case "Intermediate":
        return 2;
      case "Basic":
      case "Beginner":
        return 1;
      default:
        return 0;
    }
  };

  // Custom renderers for sidebar sections
  const renderSkillsForSidebar = () => {
    // First check if there's any data to render
    if (!formData.skills || formData.skills.length === 0) return null;
    
    console.log('Rendering skills for sidebar:', formData.skills); // Debug log
    
    return (
      <Box className={classes.sidebarSection} data-section="Skills">
        <Typography className={classes.sidebarSectionTitle}>
          <FontAwesomeIcon icon={faCode} className={classes.contactIcon} />
          {' Skills'}
        </Typography>
        {formData.skills.map((item) => (
          <Box key={item.id || item.skillName || item.category || Math.random()} className={classes.skillCategory}>
            <Typography className={classes.sidebarItemTitle}>
              {item.category || item.skillName}
            </Typography>
            <Typography className={classes.sidebarItemSubtitle}>
              {item.skills || item.proficiency}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  const renderLanguagesForSidebar = () => {
    if (!formData.languages || formData.languages.length === 0) return null;
    
    return (
      <Box className={classes.sidebarSection} data-section="Languages">
        <Typography className={classes.sidebarSectionTitle}>
          <FontAwesomeIcon icon={faLanguage} className={classes.contactIcon} />
          {' Languages'}
        </Typography>
        {formData.languages.map((item) => (
          <Box key={item.id || item.language || Math.random()} className={classes.skillCategory}>
            <Typography className={classes.sidebarItemTitle}>
              {item.language}
            </Typography>
            <Box className={classes.languageLevel}>
              {Array.from({ length: 5 }).map((_, i) => (
                <Box
                  key={i}
                  className={`${classes.languageDot} ${
                    i < getProficiencyLevel(item.proficiency) 
                      ? classes.languageDotFilled 
                      : classes.languageDotEmpty
                  }`}
                  sx={{
                    backgroundColor: i < getProficiencyLevel(item.proficiency) 
                      ? design?.colors?.[`accent${i+1}`] || design?.colors?.primary || '#000' 
                      : undefined
                  }}
                />
              ))}
              <Typography className={classes.smallText} sx={{ marginLeft: '4px' }}>
                {item.proficiency}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  const renderCertificationsForSidebar = () => {
    if (!formData.certifications || formData.certifications.length === 0) return null;
    
    return (
      <Box className={classes.sidebarSection} data-section="Certifications">
        <Typography className={classes.sidebarSectionTitle}>
          <FontAwesomeIcon icon={faCertificate} className={classes.contactIcon} />
          {' Certifications'}
        </Typography>
        {formData.certifications.map((item) => (
          <Box key={item.id || item.name || Math.random()} className={classes.skillCategory}>
            <Typography className={classes.sidebarItemTitle}>
              {item.name || item.certificationName}
            </Typography>
            <Typography className={classes.sidebarItemSubtitle}>
              {item.issuer || item.issuingOrganization}
              {(item.date || item.issueDate) && ` | ${BaseLayout.formatDate(item.date || item.issueDate)}`}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  // Add helper function
  const hasSidebarData = (formData) => {
    return (
      (formData.skills && formData.skills.length > 0) ||
      (formData.languages && formData.languages.length > 0) ||
      (formData.certifications && formData.certifications.length > 0)
    );
  };

  // Filter sections for main content and sidebar
  const mainSections = selectedSections.filter(
    section => !sidebarSectionNames.includes(section.name) || currentPage > 0
  );

  const sidebarSections = currentPage === 0 
    ? selectedSections.filter(section => sidebarSectionNames.includes(section.name))
    : [];

  return (
    <Box
      className={classes.container}
      sx={{
        // Apply measurement mode styles
        ...(measureMode ? {
          position: 'absolute',
          visibility: 'hidden'
        } : {}),
        // Apply PDF export styles
        ...(isPdfExport ? {
          position: 'relative',
          visibility: 'visible',
        } : {}),
      }}
    >
      {/* Main Content - on the left in this layout */}
      <Box className={classes.mainContent}>
        {/* Render each section using BaseLayout's renderer functions */}
        {mainSections.map((section) => {
          // Skip sidebar sections on first page since they're in the sidebar
          if (currentPage === 0 && sidebarSectionNames.includes(section.name)) {
            return null;
          }
          
          switch(section.name) {
            case 'Professional Summary':
              return BaseLayout.renderProfessionalSummary(formData, classes, design?.colors);
            case 'Work Experience':
              return BaseLayout.renderWorkExperiences(formData, classes, design?.colors);
            case 'Education':
              return BaseLayout.renderEducations(formData, classes, design?.colors);
            case 'Skills':
              return BaseLayout.renderSkills(formData, classes, design?.colors);
            case 'Projects':
              return BaseLayout.renderProjects(formData, classes, design?.colors);
            case 'Certifications':
              return BaseLayout.renderCertifications(formData, classes, design?.colors);
            case 'Awards and Honors':
              return BaseLayout.renderAwardsAndHonors(formData, classes, design?.colors);
            case 'Languages':
              return BaseLayout.renderLanguages(formData, classes, design?.colors);
            case 'Volunteer Experience':
              return BaseLayout.renderVolunteerExperiences(formData, classes, design?.colors);
            case 'Publications':
              return BaseLayout.renderPublications(formData, classes, design?.colors);
            case 'References':
              return BaseLayout.renderReferences(formData, classes, design?.colors);
            default:
              return null;
          }
        })}
      </Box>

      {/* Sidebar - Only on first page, positioned on the right */}
      {currentPage === 0 && (
        <Box className={classes.sidebar}>
          {/* Profile Photo */}
          {personalInfo.photo && (
            <Avatar
              src={personalInfo.photo}
              alt={`${personalInfo.firstName} ${personalInfo.lastName}`}
              className={classes.profilePhoto}
            />
          )}
          
          {/* Name */}
          {personalInfo.firstName && personalInfo.lastName && (
            <Typography className="headerName">
              {BaseLayout.safeRender(personalInfo.firstName)} {BaseLayout.safeRender(personalInfo.lastName)}
            </Typography>
          )}
          
          {/* Professional Title */}
          {personalInfo.professionalTitle && (
            <Typography className="headerTitle">
              {BaseLayout.safeRender(personalInfo.professionalTitle)}
            </Typography>
          )}
          
          {/* Contact Information */}
          <Box className={classes.contactSection}>
            {personalInfo.phone && (
              <Box className={classes.contactItem}>
                <FontAwesomeIcon icon={faPhone} className={classes.contactIcon} />
                {BaseLayout.safeRender(personalInfo.phone)}
              </Box>
            )}
            {personalInfo.email && (
              <Box className={classes.contactItem}>
                <FontAwesomeIcon icon={faAt} className={classes.contactIcon} />
                {BaseLayout.safeRender(personalInfo.email)}
              </Box>
            )}
            {personalInfo.linkedin && (
              <Box className={classes.contactItem}>
                <FontAwesomeIcon icon={faLinkedin} className={classes.contactIcon} />
                {BaseLayout.safeRender(personalInfo.linkedin)}
              </Box>
            )}
            {personalInfo.github && (
              <Box className={classes.contactItem}>
                <FontAwesomeIcon icon={faGithub} className={classes.contactIcon} />
                {BaseLayout.safeRender(personalInfo.github)}
              </Box>
            )}
            {personalInfo.address && (
              <Box className={classes.contactItem}>
                <FontAwesomeIcon icon={faMapMarkerAlt} className={classes.contactIcon} />
                {BaseLayout.safeRender(personalInfo.address)}
              </Box>
            )}
          </Box>
          
          {/* Sidebar Sections with custom renderers */}
          {/* Always render sidebar sections that have data */}
          {formData.skills && formData.skills.length > 0 && renderSkillsForSidebar()}
          {formData.languages && formData.languages.length > 0 && renderLanguagesForSidebar()}
          {formData.certifications && formData.certifications.length > 0 && renderCertificationsForSidebar()}
        </Box>
      )}

      {/* Page number */}
      {totalPages > 1 && !measureMode && (
        <Typography
          sx={{
            position: 'absolute',
            bottom: '10mm',
            right: '10mm',
            fontSize: '10px',
            color: design?.colors?.textSecondary || '#666',
          }}
        >
          Page {currentPage + 1} of {totalPages}
        </Typography>
      )}
    </Box>
  );
};

export default SidebarRightLayout; 