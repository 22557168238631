import React, { createContext, useState } from 'react';

export const PaginationContext = createContext();

export function PaginationProvider({ children }) {
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isExporting, setIsExporting] = useState(false);

  const setCurrentPageSafe = (page) => {
    setIsExporting(true);
    setCurrentPage(page);
    setTimeout(() => setIsExporting(false), 300);
  };

  const value = {
    pages,
    setPages,
    currentPage,
    setCurrentPage: setCurrentPageSafe,
    isExporting,
  };

  return (
    <PaginationContext.Provider value={value}>
      {children}
    </PaginationContext.Provider>
  );
}

const PaginationContextExports = {
  PaginationContext,
  PaginationProvider
};

export default PaginationContextExports; 