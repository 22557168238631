import React, { useState, useCallback } from 'react';
import { Button, CircularProgress } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { exportPdf } from '../services/PdfService';

// Enhanced export button with loading state and performance optimization
const ExportButton = React.memo(({ 
  previewRef, 
  setShowPreview, 
  variant = "contained", 
  color = "primary" 
}) => {
  const [isExporting, setIsExporting] = useState(false);
  
  // Memoize the export handler to prevent unnecessary re-renders
  const handleExportPDF = useCallback(async () => {
    if (isExporting) return;
    
    setIsExporting(true);
    
    try {
      // Show progress indicator immediately
      document.body.style.cursor = 'wait';
      
      // Use setTimeout to ensure the UI updates before heavy processing begins
      setTimeout(async () => {
        try {
          await exportPdf(previewRef, setShowPreview);
          // Reset states
          setIsExporting(false);
          document.body.style.cursor = 'default';
        } catch (error) {
          console.error("Failed to export PDF:", error);
          alert("There was a problem generating your PDF. Please try again.");
          setIsExporting(false);
          document.body.style.cursor = 'default';
        }
      }, 50);
    } catch (error) {
      console.error("Error initiating PDF export:", error);
      setIsExporting(false);
      document.body.style.cursor = 'default';
    }
  }, [isExporting, previewRef, setShowPreview]);
  
  return (
    <Button
      variant={variant}
      color={color}
      startIcon={isExporting ? <CircularProgress size={20} color="inherit" /> : <PictureAsPdfIcon />}
      onClick={handleExportPDF}
      disabled={isExporting}
    >
      {isExporting ? "Exporting..." : "Export PDF"}
    </Button>
  );
});

export default ExportButton; 