import React from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  useTheme, 
  useMediaQuery,
  Grid,
} from '@mui/material';
import { useLayout } from '../context/LayoutContext';

// Import placeholder icons from MUI if SVGs aren't available yet
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';

function LayoutSelector() {
  const { layouts, selectedLayout, setSelectedLayout } = useLayout();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Use MUI icons as fallbacks if SVG thumbnails aren't available
  const layoutIcons = {
    'centered-header': <ViewAgendaIcon sx={{ fontSize: 40 }} />,
    'right-aligned-header': <ViewColumnIcon sx={{ fontSize: 40 }} />,
    'sidebar-left': <ViewSidebarIcon sx={{ fontSize: 40 }} />,
    'sidebar-right': <ViewQuiltIcon sx={{ fontSize: 40, transform: 'scaleX(-1)' }} />,
  };

  // Try loading SVG files but fallback to icons if they're not available
  const getThumbnail = (layoutId) => {
    try {
      // Try to load the SVG, but this might fail if files aren't created yet
      const thumbnailPath = require(`../assets/${layoutId}-layout-thumbnail.svg`);
      return <img src={thumbnailPath} alt={layoutId} style={{ maxWidth: '100%', maxHeight: '100%' }} />;
    } catch (e) {
      // Fallback to MUI icons
      return layoutIcons[layoutId];
    }
  };

  // Layout selection content
  return (
    <Box>
      <Grid container spacing={2}>
        {Object.values(layouts).map((layout) => (
          <Grid item xs={12} sm={6} key={layout.id}>
            <Paper 
              elevation={selectedLayout === layout.id ? 4 : 1}
              onClick={() => setSelectedLayout(layout.id)}
              sx={{
                p: 2,
                cursor: 'pointer',
                border: selectedLayout === layout.id ? `2px solid ${theme.palette.primary.main}` : '1px solid #ddd',
                minHeight: '200px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'all 0.2s ease',
                '&:hover': {
                  borderColor: theme.palette.primary.light,
                  boxShadow: 2,
                }
              }}
            >
              <Typography component="h3" sx={{ mb: 1, textAlign: 'center' }}>
                {layout.name}
              </Typography>
              
              <Box 
                sx={{ 
                  position: 'relative',
                  height: '100px',
                  mb: 1.5,
                  overflow: 'hidden',
                }}
              >
                <Box 
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {getThumbnail(layout.id)}
                </Box>
              </Box>
              <Typography 
                variant="body2" 
                color="text.secondary" 
                sx={{ 
                  overflow: 'auto',
                  textAlign: 'center',
                  fontSize: '12px',
                }}
              >
                {layout.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default LayoutSelector; 