// src/components/SkillsCard.js
import React from 'react';
import { Grid, TextField } from '@mui/material';
import SectionCardFactory from '../shared/SectionCardFactory';
import components from '../../../theme/components';

const SkillsCard = SectionCardFactory({
  sectionName: "Skills",
  sectionKey: "skills",
  
  initialItemFields: () => ({
    category: '',
    skills: '',
    expanded: true,
  }),
  
  generateTitle: (item, index) => 
    item.category || `Skill Category ${index + 1}`,
  
  renderFields: ({ item, handleChange }) => (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Skill Category"
          value={item.category}
          onChange={(e) => handleChange('category', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          rows={3}
          label="Skills (comma separated)"
          value={item.skills}
          onChange={(e) => handleChange('skills', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
    </>
  )
});

export default SkillsCard;
