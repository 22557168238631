import React from 'react';

// This component will help us find where objects are being rendered as React children
function DebugComponent() {
  // Add this to App.js to see what's being rendered
  console.log('Rendering App component');
  
  // Add a global error handler to catch and log rendering errors
  if (!window._errorHandlerAdded) {
    window._errorHandlerAdded = true;
    
    const originalConsoleError = console.error;
    console.error = function(...args) {
      // Check if this is the specific error we're looking for
      if (args[0] && typeof args[0] === 'string' && args[0].includes('Objects are not valid as a React child')) {
        console.log('FOUND INVALID OBJECT RENDER ERROR:');
        console.trace();
        
        // Try to extract the object from the error message
        const match = args[0].match(/found: object with keys \{([^}]+)\}/);
        if (match) {
          console.log('Object keys:', match[1]);
        }
      }
      
      // Call the original console.error
      originalConsoleError.apply(console, args);
    };
    
    // Add a global error boundary
    window.addEventListener('error', function(event) {
      console.log('Global error caught:', event.error);
      // Prevent the error from bubbling up
      event.preventDefault();
    });
  }
  
  return null;
}

export default DebugComponent; 