// src/components/ProfessionalSummaryCard.js
import React, { useContext, useState } from 'react';
import { FormContext } from '../../../context/FormContext';
import BaseCard from './BaseCard';
import { RichTextField } from '../shared/FormFields';

function ProfessionalSummaryCard({ onRemove, dragHandle, onSelect, isSelected }) {
  const { formData, updateFormData } = useContext(FormContext);
  const [expanded, setExpanded] = useState(true);

  return (
    <BaseCard
      title="Professional Summary"
      onRemove={onRemove}
      dragHandle={dragHandle}
      onSelect={onSelect}
      isSelected={isSelected}
      expanded={expanded}
      onExpandClick={() => setExpanded(!expanded)}
    >
      <RichTextField
        value={formData.professionalSummary}
        onChange={(value) => updateFormData('professionalSummary', value)}
      />
    </BaseCard>
  );
}

export default ProfessionalSummaryCard;
