// src/components/InputSection/cards/CertificationsCard.js
import React from 'react';
import { Grid, TextField } from '@mui/material';
import { DateField } from '../shared/FormFields';
import SectionCardFactory from '../shared/SectionCardFactory';
import components from '../../../theme/components';

const CertificationsCard = SectionCardFactory({
  sectionName: "Certifications",
  sectionKey: "certifications",
  
  initialItemFields: () => ({
    name: '',
    issuer: '',
    date: null,
    credentialId: '',
    credentialUrl: '',
    expanded: true,
  }),
  
  generateTitle: (item, index) => 
    item.name 
      ? `${item.name}${item.issuer ? ` - ${item.issuer}` : ''}`
      : `Certification ${index + 1}`,
  
  renderFields: ({ item, handleChange }) => (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Certification Name"
          value={item.name}
          onChange={(e) => handleChange('name', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Issuing Organization"
          value={item.issuer}
          onChange={(e) => handleChange('issuer', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField
          label="Issue Date"
          value={item.date}
          onChange={(date) => handleChange('date', date)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Credential ID"
          value={item.credentialId}
          onChange={(e) => handleChange('credentialId', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Credential URL"
          value={item.credentialUrl}
          onChange={(e) => handleChange('credentialUrl', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
    </>
  )
});

export default CertificationsCard;
