import { Box, Button } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import { FormContext } from "../../context/FormContext";
import { useContext } from "react";

function ClearAllData({ setSelectedSections }) {
    const { updateFormData } = useContext(FormContext);

    const handleClearAll = () => {
        // Reset selected sections
        setSelectedSections([]);
    
        // Reset form data through context using the default structure
        updateFormData(null, {
          personalInfo: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            address: '',
            photo: '',
            linkedin: '',
            github: '',
            professionalTitle: '',
          },
          professionalSummary: '',
          workExperiences: [],
          educations: [],
          skills: [],
          projects: [],
          certifications: [],
          awardsAndHonors: [],
          languages: [],
          volunteerExperiences: [],
          publications: [],
          references: [],
        });
      };
    
    return (
        <Box sx={{ p: 2, textAlign: 'center' }}>
            <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteOutline />}
                onClick={handleClearAll}
            >
            Clear All Data
            </Button>
        </Box>);
}

export default ClearAllData;