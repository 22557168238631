// Orange design based on Amazon's color palette
const OrangeDesign = {
  id: 'orange',
  name: 'Orange Tech',
  description: 'Bold dynamic design with orange accents',
  colors: {
    primary: "#FF9900",    // Amazon Orange
    secondary: "#232F3E",  // Amazon Navy
    tertiary: "#146EB4",   // Amazon Blue
    quaternary: "#37475A", // Amazon Dark Gray
    accent1: "#FEBD69",    // Amazon Light Orange
    accent2: "#131921",    // Amazon Dark Navy
    accent3: "#00A8E1",    // Amazon Light Blue
    accent4: "#48A3C6",    // Amazon Teal
    accent5: "#4D5F6C",    // Amazon Medium Gray
    accent6: "#F3A847",    // Amazon Gold
    accent7: "#48A3C6",    // Amazon Teal
    accent8: "#007185",    // Amazon Dark Teal
    text: "#111111",       // Amazon Dark Text
    textSecondary: "#565959", // Amazon Secondary Text
    background: "#ffffff", // White background
    sidebarBackground: "#F5F5F5", // Light gray background
    border: "#DDDDDD",     // Amazon border color
  },
  typography: {
    heading: {
      fontSize: 28,
      fontFamily: "'Amazon Ember', 'Arial', sans-serif",
      lineHeight: 1.4,
    },
    subheading: {
      fontSize: 18,
      fontFamily: "'Amazon Ember', 'Arial', sans-serif",
    },
    body: {
      fontSize: 14,
      fontFamily: "'Amazon Ember', 'Arial', sans-serif",
    },
    small: {
      fontSize: 12,
      fontFamily: "'Amazon Ember', 'Arial', sans-serif",
    },
    globalFontSize: 14,
    globalLineHeight: 1.5,
  }
};

export default OrangeDesign; 