// src/components/PersonalInfoCard.js
import React, { useContext, useState } from "react";
import { Grid, Button, Avatar, TextField, useMediaQuery, useTheme } from "@mui/material";
import { FormContext } from "../../../context/FormContext";
import BaseCard from './BaseCard';
import tokens from '../../../theme/tokens';
import components from '../../../theme/components';
import { FormGridContainer } from '../shared/FormFields';

function PersonalInfoCard() {
  const { formData, updateFormData } = useContext(FormContext);
  // Ensure personalInfo exists with default values
  const personalInfo = formData.personalInfo || {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    photo: '',
    linkedin: '',
    github: '',
  };
  
  const [expanded, setExpanded] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (e) => {
    updateFormData("personalInfo", {
      ...personalInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleUploadPicture = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        updateFormData("personalInfo", {
          ...personalInfo,
          photo: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <BaseCard 
      title="Personal Information" 
      isSelected={true} 
      defaultExpanded={true}
      showRemoveButton={false}
      dragHandle={null}
      expanded={expanded}
      onExpandClick={() => setExpanded(!expanded)}
    >
      <FormGridContainer container spacing={isMobile ? 1 : tokens.spacing.input.gap} isMobile={isMobile}>
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar
            src={personalInfo.photo || ''}
            alt="Profile Picture"
            sx={{ 
              width: isMobile ? tokens.spacing[10] : tokens.spacing[12],
              height: isMobile ? tokens.spacing[10] : tokens.spacing[12],
              mb: tokens.spacing[2],
            }}
          />
          <Button 
            variant="contained"
            component="label"
            sx={{ mb: tokens.spacing[1] }}
          >
            Upload Picture
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={handleUploadPicture}
            />
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="First Name"
            name="firstName"
            value={personalInfo.firstName || ''}
            onChange={handleChange}
            sx={components.input.root}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Last Name"
            name="lastName"
            value={personalInfo.lastName || ''}
            onChange={handleChange}
            sx={components.input.root}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={personalInfo.email || ''}
            onChange={handleChange}
            sx={components.input.root}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Phone"
            name="phone"
            value={personalInfo.phone || ''}
            onChange={handleChange}
            sx={components.input.root}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Address"
            name="address"
            value={personalInfo.address || ''}
            onChange={handleChange}
            sx={components.input.root}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="LinkedIn"
            name="linkedin"
            value={personalInfo.linkedin || ''}
            onChange={handleChange}
            placeholder="https://linkedin.com/in/yourprofile"
            sx={components.input.root}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="GitHub"
            name="github"
            value={personalInfo.github || ''}
            onChange={handleChange}
            placeholder="https://github.com/yourusername"
            sx={components.input.root}
          />
        </Grid>
      </FormGridContainer>
    </BaseCard>
  );
}

export default PersonalInfoCard;
