import React from 'react';
import { Grid } from '@mui/material';
import SectionCard from '../SectionCard';
import SubCard from './SubCard';
import tokens from '../../../theme/tokens';

/**
 * Factory function that creates section card components with consistent structure
 * @param {Object} config - Configuration for the section card
 * @returns {Function} A component that renders a section card
 */
const SectionCardFactory = (config) => {
  return function GeneratedSectionCard({ onRemove, dragHandle, onSelect, isSelected }) {
    const initialItem = () => ({
      id: Date.now(),
      expanded: true,
      ...config.initialItemFields(),
    });

    const renderSubCard = ({
      item,
      index,
      provided,
      removeItem,
      setItems,
      items,
    }) => {
      const handleChange = (name, value) => {
        const newItems = [...items];
        newItems[index][name] = value;
        setItems(newItems);
      };

      // Generate title based on config
      const title = config.generateTitle(item, index);

      return (
        <SubCard 
          item={item} 
          index={index} 
          provided={provided} 
          removeItem={removeItem}
          title={title}
        >
          <Grid container spacing={tokens.spacing[2]}>
            {config.renderFields({ item, handleChange })}
          </Grid>
        </SubCard>
      );
    };

    return (
      <SectionCard
        sectionName={config.sectionName}
        sectionKey={config.sectionKey}
        initialItem={initialItem}
        renderSubCard={renderSubCard}
        dragHandle={dragHandle}
        onRemove={onRemove}
        onSelect={onSelect}
        isSelected={isSelected}
      />
    );
  };
};

export default SectionCardFactory; 