// Colorful design based on Google's material colors
const ColorfulDesign = {
  id: 'colorful',
  name: 'Colorful Tech',
  description: 'Vibrant tech-inspired design with multiple colors',
  colors: {
    primary: "#4285F4", // Blue
    secondary: "#EA4335", // Red
    tertiary: "#FBBC05", // Yellow
    quaternary: "#34A853", // Green
    accent1: "#00ACC1", // Cyan
    accent2: "#673AB7", // Purple
    accent3: "#3F51B5", // Indigo
    accent4: "#009688", // Teal
    accent5: "#FF9800", // Orange
    accent6: "#FF5722", // Deep Orange
    accent7: "#795548", // Brown
    accent8: "#607D8B", // Blue Gray
    text: "#202124", // Primary text color
    textSecondary: "#5f6368", // Secondary text color
    background: "#ffffff", // Main background
    sidebarBackground: "#f8f9fa", // Sidebar background
    border: "#dadce0", // Border color
  },
  typography: {
    heading: {
      fontSize: 28,
      fontFamily: "'Google Sans', 'Product Sans', 'Roboto', sans-serif",
      lineHeight: 1.4,
    },
    subheading: {
      fontSize: 18,
      fontFamily: "'Google Sans', 'Product Sans', 'Roboto', sans-serif",
    },
    body: {
      fontSize: 14,
      fontFamily: "'Roboto', 'Arial', sans-serif",
    },
    small: {
      fontSize: 12,
      fontFamily: "'Roboto', 'Arial', sans-serif",
    },
    globalFontSize: 14,
    globalLineHeight: 1.5,
  }
};

export default ColorfulDesign; 