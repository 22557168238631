// src/components/LivePreview.js
import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { FormContext } from '../../context/FormContext';
import { PaginationContext } from '../../context/PaginationContext';
import { ThemeContext } from '../../context/ThemeContext';
import { useLayout } from '../../context/LayoutContext';
import { useDesign } from '../../context/DesignContext';
import { useTypography } from '../../context/TypographyContext';
import LayoutResolver from '../../layouts/LayoutResolver';
import PageControls from './PageControls';
import ClearAllData from './ClearAllData';

// A4 constants
const A4_WIDTH_MM = 210;
const A4_HEIGHT_MM = 297;
const A4_RATIO = A4_HEIGHT_MM / A4_WIDTH_MM; // 1.414

// Zoom constants
const MOBILE_DEFAULT_SCALE = 0.5;  // 50% for mobile
const DESKTOP_DEFAULT_SCALE = 0.6; // 60% for desktop
const MIN_SCALE = 0.2;             // 20% minimum zoom
const MAX_SCALE = 1.5;             // 150% maximum zoom

function LivePreview({ selectedSections, setSelectedSections }) {
  const { formData, updateFormData } = useContext(FormContext);
  const { pages, setPages, currentPage, setCurrentPage } = useContext(PaginationContext);
  const { isDarkMode } = useContext(ThemeContext);
  const { selectedLayout } = useLayout();
  const { activeDesign } = useDesign();
  const { typographySettings } = useTypography();
  
  const contentRef = useRef(null);
  const previewContainerRef = useRef(null);
  const a4ContainerRef = useRef(null);
  const isInitialMount = useRef(true);
  const [isCalculating, setIsCalculating] = useState(false);
  const isDragging = useRef(false);
  const lastPosition = useRef({ x: 0, y: 0 });
  const lastPinchDistance = useRef(null);
  const pinchStartScale = useRef(0);
  const pinchMidpoint = useRef({ x: 0, y: 0 });
  
  // Add media queries for responsive behavior
  const isNarrowScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery('(max-width:900px)');
  
  // Use the appropriate default scale based on screen size
  const defaultScale = isNarrowScreen ? MOBILE_DEFAULT_SCALE : DESKTOP_DEFAULT_SCALE;
  
  // Initialize scale with the default value
  const [scale, setScale] = useState(defaultScale);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const theme = useTheme();

  // Add an initialization effect that runs once
  useEffect(() => {
    // Explicitly set the initial scale to the default value
    setScale(defaultScale);
  }, [defaultScale]);

  // Update scale adjustment logic
  useEffect(() => {
    const adjustScaleToFit = () => {
      if (!previewContainerRef.current) return;
      
      const container = previewContainerRef.current;
      
      // Get available space (accounting for padding)
      const availableWidth = container.clientWidth - 40;
      const availableHeight = container.clientHeight - 40;
      
      // Calculate scale based on available space while maintaining A4 ratio
      let newScale;
      
      // If container is narrower proportionally than A4
      if (availableWidth / availableHeight < 1 / A4_RATIO) {
        // Width constrained - scale based on width
        newScale = availableWidth / A4_WIDTH_MM;
      } else {
        // Height constrained - scale based on height
        newScale = availableHeight / A4_HEIGHT_MM;
      }
      
      // Apply minimum scale to ensure visibility
      newScale = Math.max(newScale, MOBILE_DEFAULT_SCALE);
      
      // Only auto-scale if it would result in a smaller scale than default
      // This ensures our default scale is respected on initial load
      if (newScale < defaultScale) {
        // Update scale state
        setScale(newScale);
        
        // Reset position when auto-scaling
        setPosition({ x: 0, y: 0 });
      } else {
        // Use the default scale instead
        setScale(defaultScale);
        setPosition({ x: 0, y: 0 });
      }
    };
    
    // Delay the auto-scaling to let initial render complete with default scale
    const timerId = setTimeout(adjustScaleToFit, 300);
    
    // Add window resize listener
    window.addEventListener('resize', adjustScaleToFit);
    
    return () => {
      clearTimeout(timerId);
      window.removeEventListener('resize', adjustScaleToFit);
    };
  }, [isNarrowScreen, isMediumScreen, defaultScale]);

  // Pagination logic - calculate content section heights and create pages
  const calculatePages = useCallback(() => {
    if (isCalculating || !contentRef.current || selectedSections.length === 0) return;
    
    setIsCalculating(true);
    
    try {
      // Start with a clean measurement approach
      const container = contentRef.current;
      
      // First, make sure all sections are properly rendered in the hidden container
      // The hidden container should have the exact A4 width to ensure proper text wrapping
      container.style.width = `${A4_WIDTH_MM}mm`;
      
      // Get all section elements after ensuring they're rendered properly
      const sectionElements = container.querySelectorAll('[data-section]');
      if (!sectionElements.length) {
        console.warn('No sections found for measurement');
        return;
      }
      
      // Calculate A4 page height in actual pixels (not scaled pixels)
      // Use getBoundingClientRect to get actual pixel dimensions of an A4 page
      const a4Container = a4ContainerRef.current;
      const a4Rect = a4Container.getBoundingClientRect();
      const a4HeightPx = a4Rect.height;
      
      // Give a small buffer for inter-section spacing (10px between sections)
      const spacingBuffer = (selectedSections.length - 1) * 10;
      const contentHeightPx = a4HeightPx - spacingBuffer;
      
      // Debug measurements
      console.log(`A4 height: ${a4HeightPx}px, Content height: ${contentHeightPx}px`);
      
      // Initialize pages with tracking variables
      let pagesResult = [];
      let currentPageSections = [];
      let currentPageHeight = 0;
      let debugTotalHeight = 0;
      
      // Loop through sections and measure actual rendered heights
      sectionElements.forEach((section, index) => {
        const sectionName = section.getAttribute('data-section');
        const sectionObj = selectedSections.find(s => s.name === sectionName);
        if (!sectionObj) return;
        
        // Get precise height of this section
        const rect = section.getBoundingClientRect();
        const sectionHeight = rect.height;
        debugTotalHeight += sectionHeight;
        
        // Log section measurements for debugging
        console.log(`Section ${sectionName}: ${sectionHeight}px`);
        
        // Special handling for header - always on first page
        if (sectionName === 'header') {
          if (pagesResult.length === 0) {
            currentPageSections = [sectionObj];
            currentPageHeight = sectionHeight;
          }
          return;
        }
        
        // If this section fits on current page or it's the first section of a page
        if (currentPageSections.length === 0 || currentPageHeight + sectionHeight <= contentHeightPx) {
          currentPageSections.push(sectionObj);
          currentPageHeight += sectionHeight;
          
          // Add spacing between sections (except for the last section)
          if (index < sectionElements.length - 1) {
            currentPageHeight += 10; // 10px spacing
          }
        } else {
          // This section doesn't fit - create a new page
          console.log(`Page break before ${sectionName}: current height ${currentPageHeight}px exceeds limit ${contentHeightPx}px`);
          pagesResult.push([...currentPageSections]);
          
          // Start new page with this section
          currentPageSections = [sectionObj];
          currentPageHeight = sectionHeight;
        }
      });
      
      // Add the last page if needed
      if (currentPageSections.length > 0) {
        pagesResult.push([...currentPageSections]);
      }
      
      // Ensure we have at least one page
      if (pagesResult.length === 0) {
        pagesResult = [[]];
      }
      
      console.log(`Total content height: ${debugTotalHeight}px, created ${pagesResult.length} pages`);
      console.log('Pages distribution:', pagesResult.map(page => page.map(section => section.name).join(', ')));
      
      // Update pages state
      setPages(pagesResult);
      
      // Reset current page if needed
      if (currentPage >= pagesResult.length) {
        setCurrentPage(Math.max(0, pagesResult.length - 1));
      }
    } catch (error) {
      console.error('Error calculating pages:', error);
    } finally {
      setIsCalculating(false);
    }
  }, [selectedSections, currentPage, setCurrentPage, setPages, isCalculating]);

  // Enhance the render complete handler to ensure content is fully rendered
  const handleRenderComplete = useCallback(() => {
    // Delay calculation to ensure DOM is fully rendered with current styles
    setTimeout(() => {
      // Force a browser layout calculation first
      if (contentRef.current) {
        // This forces a reflow, ensuring all layout calculations are up to date
        void contentRef.current.offsetHeight;
      }
      calculatePages();
    }, 300);
  }, [calculatePages]);

  // Add a special useEffect for typography and layout changes
  useEffect(() => {
    // When typography or layout changes, wait for rendering and recalculate
    if (!isInitialMount.current) {
      const timer = setTimeout(() => {
        console.log('Typography or layout changed, recalculating pages...');
        // Force reflow before calculation
        if (contentRef.current) {
          void contentRef.current.offsetHeight;
        }
        calculatePages();
      }, 500); // Longer timeout for style changes
      
      return () => clearTimeout(timer);
    }
  }, [typographySettings, selectedLayout, activeDesign, calculatePages]);

  // Re-calculate pages when content changes
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    
    const timer = setTimeout(() => {
      calculatePages();
    }, 300);
    
    return () => clearTimeout(timer);
  }, [calculatePages, formData, selectedSections, typographySettings, selectedLayout, activeDesign]);

  // Helper function to calculate distance between two touch points
  const getTouchDistance = (touches) => {
    if (touches.length < 2) return null;
    
    const dx = touches[0].clientX - touches[1].clientX;
    const dy = touches[0].clientY - touches[1].clientY;
    return Math.sqrt(dx * dx + dy * dy);
  };
  
  // Helper function to calculate midpoint between two touch points
  const getTouchMidpoint = (touches) => {
    if (touches.length < 2) return null;
    
    return {
      x: (touches[0].clientX + touches[1].clientX) / 2,
      y: (touches[0].clientY + touches[1].clientY) / 2
    };
  };

  // Handle mouse and touch interactions for panning and zooming the preview
  useEffect(() => {
    const a4Container = a4ContainerRef.current;
    if (!a4Container) return;
    
    const handleMouseDown = (e) => {
      // Enable dragging with any mouse button (primarily left button)
      isDragging.current = true;
      lastPosition.current = { x: e.clientX, y: e.clientY };
      a4Container.style.cursor = 'grabbing';
      e.preventDefault();
    };
    
    const handleMouseMove = (e) => {
      if (!isDragging.current) return;
      
      const deltaX = e.clientX - lastPosition.current.x;
      const deltaY = e.clientY - lastPosition.current.y;
      
      setPosition(prev => ({
        x: prev.x + deltaX,
        y: prev.y + deltaY
      }));
      
      lastPosition.current = { x: e.clientX, y: e.clientY };
    };
    
    const handleMouseUp = () => {
      if (isDragging.current) {
        isDragging.current = false;
        a4Container.style.cursor = 'grab';
      }
    };
    
    // Touch event handlers for mobile
    const handleTouchStart = (e) => {
      if (e.touches.length === 1) {
        // Single touch - pan mode
        isDragging.current = true;
        lastPosition.current = { x: e.touches[0].clientX, y: e.touches[0].clientY };
      } 
      else if (e.touches.length === 2) {
        // Two touches - pinch zoom mode
        isDragging.current = false;
        
        // Store initial pinch distance and current scale
        lastPinchDistance.current = getTouchDistance(e.touches);
        pinchStartScale.current = scale;
        
        // Store the midpoint between the touches
        pinchMidpoint.current = getTouchMidpoint(e.touches);
      }
      // Don't prevent default for touch events to allow scrolling
    };
    
    const handleTouchMove = (e) => {
      if (e.touches.length === 1 && isDragging.current) {
        // Single touch - panning
        const deltaX = e.touches[0].clientX - lastPosition.current.x;
        const deltaY = e.touches[0].clientY - lastPosition.current.y;
        
        setPosition(prev => ({
          x: prev.x + deltaX,
          y: prev.y + deltaY
        }));
        
        lastPosition.current = { x: e.touches[0].clientX, y: e.touches[0].clientY };
        
        // Prevent default to avoid page scrolling during pan
        e.preventDefault();
      } 
      else if (e.touches.length === 2 && lastPinchDistance.current !== null) {
        // Two touches - pinch zooming
        const currentDistance = getTouchDistance(e.touches);
        const scaleChange = currentDistance / lastPinchDistance.current;
        
        // Calculate new scale based on the pinch
        const newScale = Math.min(
          MAX_SCALE, 
          Math.max(MIN_SCALE, pinchStartScale.current * scaleChange)
        );
        
        // Get current midpoint
        const currentMidpoint = getTouchMidpoint(e.touches);
        
        // Update the scale
        setScale(newScale);
        
        // Prevent default to avoid page zooming
        e.preventDefault();
      }
    };
    
    const handleTouchEnd = (e) => {
      if (e.touches.length < 2) {
        // Reset pinch zoom tracking when fewer than 2 fingers
        lastPinchDistance.current = null;
      }
      
      if (e.touches.length === 0) {
        // No more touches - reset dragging state
        isDragging.current = false;
      }
    };
    
    // Set initial cursor style
    a4Container.style.cursor = 'grab';
    
    // Add event listeners
    a4Container.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    a4Container.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove, { passive: false });
    window.addEventListener('touchend', handleTouchEnd);
    
    return () => {
      // Remove event listeners
      a4Container.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      a4Container.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [scale]); // Add scale as a dependency

  // Reset position when scale changes to default
  useEffect(() => {
    if (scale === defaultScale) {
      setPosition({ x: 0, y: 0 });
    }
  }, [scale, defaultScale]);

  return (
    <Box sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      position: 'relative',
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.03)',
    }}>
      <Box sx={{ 
        flex: 1, 
        overflow: 'hidden', 
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        {/* Preview container with scroll capability */}
        <Box
          ref={previewContainerRef}
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          {/* A4 container with transform for zoom and pan */}
          <Box
            ref={a4ContainerRef}
            id="pdf-content"
            sx={{
              width: `${A4_WIDTH_MM}mm`,
              height: `${A4_HEIGHT_MM}mm`,
              backgroundColor: '#fff',
              boxShadow: theme.palette.mode === 'dark' 
                ? '0 4px 20px rgba(0,0,0,0.4)'
                : '0 4px 20px rgba(0,0,0,0.15)',
              transform: `scale(${scale}) translate(${position.x / scale}px, ${position.y / scale}px)`,
              transformOrigin: 'center center',
              transition: scale === defaultScale ? 'transform 0.2s' : 'none',
              '& *': {
                maxWidth: '100%',
                boxSizing: 'border-box',
              }
            }}
          >
            {/* Content container */}
            <Box sx={{
              width: '100%',
              height: '100%',
              position: 'relative',
              overflow: 'hidden',
            }}>
              {/* Hidden content measurement container */}
              <Box
                ref={contentRef}
                sx={{
                  position: 'absolute',
                  visibility: 'hidden',
                  zIndex: -1,
                  width: `${A4_WIDTH_MM}mm`, // Force exact A4 width
                  height: 'auto', // Allow height to grow naturally
                  '& > *': {
                    pageBreakInside: 'avoid',
                    breakInside: 'avoid',
                    maxWidth: '100%',
                  }
                }}
              >
                <LayoutResolver
                  formData={formData}
                  selectedSections={selectedSections}
                  measureMode={true}
                  currentPage={0}
                  totalPages={1}
                  onRenderComplete={handleRenderComplete}
                />
              </Box>

              {/* Visible content */}
              <LayoutResolver
                formData={formData}
                selectedSections={pages[currentPage] || []}
                currentPage={currentPage}
                totalPages={pages.length}
                onRenderComplete={null}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      
      {/* Page controls */}
      <PageControls
        scale={scale}
        setScale={setScale}
        position={position}
        setPosition={setPosition}
        isNarrowScreen={isNarrowScreen}
        defaultScale={defaultScale}
      />
      
      {/* Clear All Button - Only show on desktop */}
      {!isNarrowScreen && (
        <ClearAllData
          setSelectedSections={setSelectedSections}
        />
      )}
    </Box>
  );
}

export default LivePreview;
