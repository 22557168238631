// src/components/SectionCard.js

import React, { useState, useContext, useEffect } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FormContext } from '../../context/FormContext';
import BaseCard from './cards/BaseCard';
import tokens from '../../theme/tokens';

function SectionCard({
  sectionName,
  sectionKey,
  initialItem,
  renderSubCard,
  dragHandle,
  onRemove,
  onSelect,
  isSelected,
}) {
  const { formData, updateFormData } = useContext(FormContext);
  const [items, setItems] = useState(() => formData[sectionKey] || [initialItem()]);
  const [expanded, setExpanded] = useState(true);

  // Update items when formData changes (e.g., when loading from JSON)
  useEffect(() => {
    const newItems = formData[sectionKey];
    if (newItems && JSON.stringify(newItems) !== JSON.stringify(items)) {
      setItems(newItems);
    }
  }, [formData, sectionKey]);

  // Update formData when items change locally
  useEffect(() => {
    const currentItems = formData[sectionKey] || [];
    if (JSON.stringify(currentItems) !== JSON.stringify(items)) {
      updateFormData(sectionKey, items);
    }
  }, [items]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const addItem = () => {
    setItems(prevItems => [...prevItems, initialItem()]);
  };

  const removeItem = (id) => {
    setItems(prevItems => prevItems.filter(item => item.id !== id));
  };

  const onEntryDragEnd = (result) => {
    if (!result.destination) return;

    setItems(prevItems => {
      const reorderedItems = Array.from(prevItems);
      const [movedItem] = reorderedItems.splice(result.source.index, 1);
      reorderedItems.splice(result.destination.index, 0, movedItem);
      return reorderedItems;
    });
  };

  return (
    <BaseCard
      title={sectionName}
      isSelected={isSelected}
      onRemove={onRemove}
      onSelect={onSelect}
      dragHandle={dragHandle}
      expanded={expanded}
      onExpandClick={handleExpandClick}
    >
      <DragDropContext onDragEnd={onEntryDragEnd}>
        <Droppable droppableId={`${sectionKey}Droppable`}>
          {(droppableProvided) => (
            <div 
              ref={droppableProvided.innerRef} 
              {...droppableProvided.droppableProps}
            >
              {items.map((item, index) => (
                <Draggable 
                  key={item.id.toString()} 
                  draggableId={item.id.toString()} 
                  index={index}
                >
                  {(draggableProvided) => 
                    renderSubCard({
                      item,
                      index,
                      provided: draggableProvided,
                      removeItem,
                      setItems,
                      items,
                    })
                  }
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Box sx={{ textAlign: 'center', mt: tokens.spacing[4] }}>
        <Tooltip title={`Add ${sectionName}`}>
          <IconButton onClick={addItem}>
            <AddCircleOutline />
          </IconButton>
        </Tooltip>
      </Box>
    </BaseCard>
  );
}

export default SectionCard;