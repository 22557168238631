import React, { createContext, useState, useContext, useEffect } from 'react';
import { getDesignById, getAllDesigns } from '../designs/DesignRegistry';

// Create design context
const DesignContext = createContext();

export function DesignProvider({ children }) {
  // Get all available designs from registry
  const designs = getAllDesigns();
  
  // Initialize with blue design or load from localStorage
  const [selectedDesign, setSelectedDesign] = useState(() => {
    try {
      const savedDesign = localStorage.getItem('selectedDesign');
      return savedDesign || 'blue';
    } catch (error) {
      console.error('Failed to load design settings:', error);
      return 'blue';
    }
  });

  // Get the active design object from the registry
  const activeDesign = getDesignById(selectedDesign);

  // Save design to localStorage when changed
  useEffect(() => {
    try {
      localStorage.setItem('selectedDesign', selectedDesign);
    } catch (error) {
      console.error('Failed to save design settings:', error);
    }
  }, [selectedDesign]);

  return (
    <DesignContext.Provider
      value={{
        designs,
        selectedDesign,
        setSelectedDesign,
        activeDesign
      }}
    >
      {children}
    </DesignContext.Provider>
  );
}

export function useDesign() {
  const context = useContext(DesignContext);
  if (!context) {
    throw new Error('useDesign must be used within a DesignProvider');
  }
  return context;
}

export default { DesignContext, DesignProvider, useDesign }; 