// src/theme.js

import { createTheme } from '@mui/material/styles';

export const getTheme = (isDarkMode) => createTheme({
  palette: {
    mode: isDarkMode ? 'dark' : 'light',
    primary: {
      main: isDarkMode ? '#ffffff' : '#3b82f6',
      light: isDarkMode ? '#ffffff' : '#60a5fa',
      dark: isDarkMode ? '#e2e2e2' : '#2563eb',
      contrastText: isDarkMode ? '#000000' : '#ffffff',
    },
    background: {
      default: isDarkMode ? '#121212' : '#ffffff',
      paper: isDarkMode ? '#1e1e1e' : '#f8fafc',
    },
    text: {
      primary: isDarkMode ? '#ffffff' : '#1a1a1a',
      secondary: isDarkMode ? '#a1a1aa' : '#64748b',
    },
    divider: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
  },
  typography: {
    fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, sans-serif',
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: isDarkMode ? '#121212' : '#ffffff',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: 'none',
          border: '1px solid #e2e8f0',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 4,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            backgroundColor: theme => theme.palette.mode === 'dark' 
              ? 'rgba(255,255,255,0.05)' 
              : 'transparent',
            '& fieldset': {
              borderColor: theme => theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,0.1)'
                : 'rgba(0,0,0,0.1)',
            },
            '&:hover fieldset': {
              borderColor: theme => theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,0.2)'
                : 'rgba(0,0,0,0.2)',
            },
            '&.Mui-focused fieldset': {
              borderColor: theme => theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,0.3)'
                : theme.palette.primary.main,
            },
            '&.MuiInputBase-multiline': {
              backgroundColor: theme => theme.palette.mode === 'dark' 
                ? 'rgba(255,255,255,0.05)' 
                : 'transparent',
            },
            '& input, & textarea': {
              backgroundColor: 'transparent !important',
              color: theme => theme.palette.mode === 'dark'
                ? '#fff'
                : 'inherit',
            }
          },
          marginBottom: '16px',
        }
      },
    },
  },
});

// Create a default theme for initial render
const theme = getTheme(false);
export default theme;
