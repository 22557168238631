import React, { useContext, useRef, useState } from 'react';
import { 
  Box, 
  IconButton, 
  Tooltip, 
  useTheme, 
  Menu, 
  MenuItem, 
  ListItemIcon, 
  ListItemText,
  SpeedDial,
  SpeedDialAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress
} from '@mui/material';
import { 
  TextFields as TypographyIcon,
  DeleteSweep as ClearIcon,
  Save as SaveIcon,
  FileUpload as ImportIcon,
  FileDownload as ExportIcon,
  PictureAsPdf as PdfIcon,
  Visibility as PreviewIcon,
  Edit as EditIcon,
  ViewColumn as LayoutIcon,
  Palette as DesignIcon,
  Close as CloseIcon,
  Brush as StylingIcon,
  MoreVert as MoreIcon
} from '@mui/icons-material';
import { FormContext } from '../context/FormContext';
import { useTypography } from '../context/TypographyContext';
import { exportPdf } from '../services/PdfService';
import tokens from '../theme/tokens';
import LayoutSelector from './LayoutSelector';
import DesignSelector from './DesignSelector';
import ExportButton from './ExportButton';
import TypographySettings from '../typography/TypographySettings';

function MobileNavBar({ 
  showPreview, 
  setShowPreview,
  openTypographySettings,
  previewRef,
  setSelectedSections,
  selectedSections,
  openDesignLayoutPicker
}) {
  const theme = useTheme();
  const { formData, updateFormData } = useContext(FormContext);
  const { typographySettings } = useTypography();
  const fileInputRef = useRef(null);
  
  // State for the styling dropdown menu
  const [stylingAnchorEl, setStylingAnchorEl] = useState(null);
  const openStylingMenu = Boolean(stylingAnchorEl);
  
  // State for the recovery dropdown menu
  const [recoveryAnchorEl, setRecoveryAnchorEl] = useState(null);
  const openRecoveryMenu = Boolean(recoveryAnchorEl);
  
  // State for modals
  const [typographyDialogOpen, setTypographyDialogOpen] = useState(false);
  const [layoutDialogOpen, setLayoutDialogOpen] = useState(false);
  const [designDialogOpen, setDesignDialogOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  // Handle styling menu
  const handleStylingClick = (event) => {
    setStylingAnchorEl(event.currentTarget);
  };
  
  const handleStylingClose = () => {
    setStylingAnchorEl(null);
  };

  // Handle recovery menu
  const handleRecoveryClick = (event) => {
    setRecoveryAnchorEl(event.currentTarget);
  };
  
  const handleRecoveryClose = () => {
    setRecoveryAnchorEl(null);
  };

  // Handle clearing data
  const handleClear = () => {
    if (window.confirm('Are you sure you want to clear all data? This cannot be undone.')) {
      // Reset selected sections
      setSelectedSections([]);
    
      // Reset form data through context
      updateFormData(null, {
        personalInfo: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          address: '',
          photo: '',
          linkedin: '',
          github: '',
          professionalTitle: '',
        },
        professionalSummary: '',
        workExperiences: [],
        educations: [],
        skills: [],
        projects: [],
        certifications: [],
        awardsAndHonors: [],
        languages: [],
        volunteerExperiences: [],
        publications: [],
        references: [],
      });
    }
    handleRecoveryClose();
  };

  // Export as PDF
  const handleExportPDF = async () => {
    setIsExporting(true);
    try {
      await exportPdf(previewRef, setShowPreview);
    } finally {
      setIsExporting(false);
    }
  };

  // Export as JSON
  const handleExport = () => {
    // Create export data JSON
    const exportData = {
      formData,
      selectedSections,
      settings: {
        typography: typographySettings
      }
    };
    
    // Convert to JSON string and create download
    const dataStr = JSON.stringify(exportData, null, 2);
    const dataBlob = new Blob([dataStr], { type: 'application/json' });
    const url = URL.createObjectURL(dataBlob);
    
    const a = document.createElement('a');
    a.href = url;
    a.download = 'resume_data.json';
    document.body.appendChild(a);
    a.click();
    
    // Cleanup
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    
    handleRecoveryClose();
  };

  // Import from JSON
  const handleImport = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = JSON.parse(e.target.result);
        
        // Handle imported data
        if (data.formData) {
          updateFormData(null, data.formData);
        }
        
        if (data.selectedSections) {
          setSelectedSections(data.selectedSections);
        }
        
        alert('Resume data imported successfully!');
      } catch (error) {
        console.error('Error importing data:', error);
        alert('Failed to import data. The file may be corrupted.');
      }
      
      // Reset the file input
      event.target.value = null;
    };
    
    reader.readAsText(file);
    handleRecoveryClose();
  };

  // Open modal for typography settings
  const handleOpenTypography = () => {
    setTypographyDialogOpen(true);
    handleStylingClose();
  };

  // Open modal for layout selection
  const handleOpenLayout = () => {
    setLayoutDialogOpen(true);
    handleStylingClose();
  };

  // Open modal for design selection
  const handleOpenDesign = () => {
    setDesignDialogOpen(true);
    handleStylingClose();
  };

  return (
    <Box sx={{ 
      position: 'fixed', 
      bottom: 0, 
      left: 0, 
      right: 0, 
      backgroundColor: theme.palette.background.paper,
      borderTop: `1px solid ${theme.palette.divider}`,
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: '8px 0',
      zIndex: 100,
      boxShadow: '0px -2px 4px rgba(0,0,0,0.05)'
    }}>
      {/* Styling Button */}
      <Tooltip title="Styling Options" placement="top">
        <IconButton 
          onClick={handleStylingClick}
          sx={{
            backgroundColor: theme.palette.action.hover,
            width: 40,
            height: 40,
          }}
        >
          <StylingIcon />
        </IconButton>
      </Tooltip>
      
      <Menu
        anchorEl={stylingAnchorEl}
        open={openStylingMenu}
        onClose={handleStylingClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handleOpenLayout}>
          <ListItemIcon>
            <LayoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Change Layout</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleOpenDesign}>
          <ListItemIcon>
            <DesignIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Change Design</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleOpenTypography}>
          <ListItemIcon>
            <TypographyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Typography Settings</ListItemText>
        </MenuItem>
      </Menu>

      {/* Recovery Button */}
      <Tooltip title="Import/Export" placement="top">
        <IconButton 
          onClick={handleRecoveryClick}
          sx={{
            backgroundColor: theme.palette.mode === 'dark'
              ? 'rgba(144, 202, 249, 0.2)'
              : '#bbdefb',
            color: '#1976d2',
            width: 40,
            height: 40,
          }}
        >
          <SaveIcon />
        </IconButton>
      </Tooltip>
      
      <Menu
        anchorEl={recoveryAnchorEl}
        open={openRecoveryMenu}
        onClose={handleRecoveryClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handleExport}>
          <ListItemIcon>
            <ExportIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Download ave File</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => fileInputRef.current?.click()}>
          <ListItemIcon>
            <ImportIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Upload Save File</ListItemText>
          <input
            type="file"
            accept="application/json"
            hidden
            ref={fileInputRef}
            onChange={handleImport}
          />
        </MenuItem>
      </Menu>

      {/* Export PDF Button */}
      <Tooltip title="Export PDF" placement="top">
        <IconButton 
          onClick={handleExportPDF}
          disabled={isExporting}
          sx={{
            backgroundColor: theme.palette.mode === 'dark'
              ? 'rgba(76, 175, 80, 0.2)'
              : '#c8e6c9',
            color: '#43a047',
            width: 40,
            height: 40,
          }}
        >
          {isExporting ? <CircularProgress size={24} color="inherit" /> : <PdfIcon />}
        </IconButton>
      </Tooltip>

      {/* Toggle Preview/Edit */}
      <Tooltip title={showPreview ? "Edit Resume" : "Preview Resume"} placement="top">
        <IconButton 
          onClick={() => setShowPreview(!showPreview)}
          sx={{
            backgroundColor: theme.palette.mode === 'dark'
              ? 'rgba(236, 201, 75, 0.7)'
              : '#ECC94B',
            color: '#000000',
            '&:hover': {
              backgroundColor: theme.palette.mode === 'dark'
                ? 'rgba(236, 201, 75, 0.9)'
                : '#D6B636',
            },
            width: 40,
            height: 40,
          }}
        >
          {showPreview ? <EditIcon /> : <PreviewIcon />}
        </IconButton>
      </Tooltip>

      {/* Dialogs for styling options */}
      <Dialog 
        open={layoutDialogOpen} 
        onClose={() => setLayoutDialogOpen(false)} 
        fullWidth 
        maxWidth="sm"
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Choose Layout
          <IconButton onClick={() => setLayoutDialogOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <LayoutSelector />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLayoutDialogOpen(false)}>Done</Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={designDialogOpen} 
        onClose={() => setDesignDialogOpen(false)} 
        fullWidth 
        maxWidth="sm"
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Choose Design
          <IconButton onClick={() => setDesignDialogOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DesignSelector />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDesignDialogOpen(false)}>Done</Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={typographyDialogOpen} 
        onClose={() => setTypographyDialogOpen(false)} 
        fullWidth 
        maxWidth="sm"
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Typography Settings
          <IconButton onClick={() => setTypographyDialogOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TypographySettings standalone={true} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTypographyDialogOpen(false)}>Done</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default MobileNavBar; 