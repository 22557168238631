import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Typography,
  Slider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RestoreIcon from '@mui/icons-material/Restore';
import { useTypography } from '../context/TypographyContext';
import { useDesign } from '../context/DesignContext';
import { debounce } from 'lodash';

// Replace the entire TypographySlider component with a simpler version
const PerformanceSlider = React.memo(({ name, value, onChange, min, max, step = 1, onCommit }) => {
  // Use separate state for dragging to avoid context updates during drag
  const [localValue, setLocalValue] = useState(value);
  const isDraggingRef = useRef(false);
  
  // Update local value when prop changes (only if not dragging)
  useEffect(() => {
    if (!isDraggingRef.current) {
      setLocalValue(value);
    }
  }, [value]);

  // Handle interactions
  const handleChange = (e, newValue) => {
    setLocalValue(newValue);
    onChange(newValue); // Update parent's local state
  };
  
  const handleMouseDown = () => {
    isDraggingRef.current = true;
  };
  
  const handleMouseUp = () => {
    isDraggingRef.current = false;
    onCommit(localValue); // Only update context when done dragging
  };
  
  return (
    <Slider
      value={localValue}
      onChange={handleChange}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      min={min}
      max={max}
      step={step}
      valueLabelDisplay="auto"
      name={name}
      sx={{ 
        '& .MuiSlider-thumb': {
          transition: 'none', // Remove animations for better performance
        },
        '& .MuiSlider-track': {
          transition: 'none',
        },
        '& .MuiSlider-rail': {
          transition: 'none',
        }
      }}
    />
  );
});

function TypographySettings() {
  const { typographySettings, updateTypographySettings, resetToDesignDefaults } = useTypography();
  const { activeDesign } = useDesign();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Define text sections
  const textSections = ['heading', 'subheading', 'body', 'small'];
  
  // Key to force complete re-render when settings change
  const [settingsKey, setSettingsKey] = useState(0);
  
  // Create local state that shadows the context values
  // This ensures the UI always reflects the current values when they change externally
  const [localSettings, setLocalSettings] = useState({...typographySettings});
  
  // Create separate debounced update functions for each type of setting
  const debouncedUpdateFontSize = useCallback(
    debounce((section, value) => {
      if (section === 'global') {
        updateTypographySettings({ globalFontSize: value });
      } else {
        updateTypographySettings({
          [section]: {
            ...typographySettings[section],
            fontSize: value
          }
        });
      }
    }, 200),
    [typographySettings]
  );
  
  const debouncedUpdateLineHeight = useCallback(
    debounce((section, value) => {
      if (section === 'global') {
        updateTypographySettings({ globalLineHeight: value });
      } else {
        updateTypographySettings({
          [section]: {
            ...typographySettings[section],
            lineHeight: value
          }
        });
      }
    }, 200),
    [typographySettings]
  );
  
  // Clean up all debounced functions on unmount
  useEffect(() => {
    return () => {
      debouncedUpdateFontSize.cancel();
      debouncedUpdateLineHeight.cancel();
    };
  }, [debouncedUpdateFontSize, debouncedUpdateLineHeight]);
  
  // Update handlers that use dedicated debounced functions
  const handleFontSizeChange = (section, value) => {
    // Update local state immediately for responsive UI
    setLocalSettings(prev => {
      const newSettings = {...prev};
      
      if (section === 'global') {
        newSettings.globalFontSize = value;
      } else {
        newSettings[section] = {
          ...newSettings[section],
          fontSize: value
        };
      }
      
      return newSettings;
    });
    
    // Use the section-specific debounced update
    debouncedUpdateFontSize(section, value);
  };
  
  const handleLineHeightChange = (section, value) => {
    // Update local state immediately
    setLocalSettings(prev => {
      const newSettings = {...prev};
      
      if (section === 'global') {
        newSettings.globalLineHeight = value;
      } else {
        newSettings[section] = {
          ...newSettings[section],
          lineHeight: value
        };
      }
      
      return newSettings;
    });
    
    // Use the section-specific debounced update
    debouncedUpdateLineHeight(section, value);
  };
  
  // Font family doesn't need debouncing (instant change is fine)
  const handleFontFamilyChange = (section, event) => {
    const value = event.target.value;
    
    // Update local state immediately
    setLocalSettings(prev => {
      const newSettings = {...prev};
      
      newSettings[section] = {
        ...newSettings[section],
        fontFamily: value
      };
      
      return newSettings;
    });
    
    // Update context immediately (no debounce)
    updateTypographySettings({
      [section]: {
        ...typographySettings[section],
        fontFamily: value
      }
    });
  };

  // Handle reset to design defaults
  const handleResetToDefaults = () => {
    resetToDesignDefaults();
    // Local state will be updated via the effect when typographySettings changes
    setSettingsKey(prev => prev + 1);
  };

  // Define font family options
  const fontFamilies = [
    "'Arial', sans-serif",
    "'Helvetica', sans-serif",
    "'Roboto', sans-serif",
    "'Times New Roman', serif",
    "'Georgia', serif",
    "'Courier New', monospace",
    "'Open Sans', sans-serif",
    "'Lato', sans-serif",
    "'Montserrat', sans-serif",
    "'Product Sans', 'Roboto', sans-serif",
    "'SF Pro Text', 'Helvetica', sans-serif",
  ];

  // Render a settings section for a text category
  const renderSection = (id, title, content) => {
    return (
      <Accordion
        key={id}
        expanded={localSettings[id]}
        onChange={(event, isExpanded) => {
          if (!isMobile) {
            setLocalSettings(prev => ({
              ...prev,
              [id]: isExpanded,
            }));
          }
        }}
        sx={{
          mb: 1,
          backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.03)' : 'rgba(0,0,0,0.01)',
          border: `1px solid ${theme.palette.divider}`,
          '&:before': {
            display: 'none',
          },
          boxShadow: 'none',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            minHeight: '48px',
            '& .MuiAccordionSummary-content': {
              margin: '8px 0',
            },
          }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: 'medium' }}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ pt: 1, pb: 1 }}>
            {content}
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  };

  // Render the global typography settings section
  const renderGlobalSection = () => {
    return (
      <Box sx={{ mb: 3 }} key={`global-controls-${settingsKey}`}>
        <Typography
          variant="body2"
          gutterBottom
          sx={{ mt: 1, color: theme.palette.text.secondary }}
        >
          Base Font Size
        </Typography>
        <PerformanceSlider
          name="global-font-size"
          value={localSettings.globalFontSize}
          onChange={(val) => handleFontSizeChange('global', val)}
          onCommit={(val) => handleFontSizeChange('global', val)}
          min={10}
          max={18}
          aria-labelledby="global-font-size-slider"
          marks={[
            { value: 10, label: '10px' },
            { value: 18, label: '18px' },
          ]}
        />
        
        <Typography
          variant="body2"
          gutterBottom
          sx={{ mt: 2, color: theme.palette.text.secondary }}
        >
          Base Line Height
        </Typography>
        <PerformanceSlider
          name="globalLineHeight"
          value={localSettings.globalLineHeight}
          onChange={(val) => handleLineHeightChange('global', val)}
          min={1}
          max={2.5}
          step={0.1}
          onCommit={(val) => handleLineHeightChange('global', val)}
        />
      </Box>
    );
  };

  // Create content for both dialog and embedded mode
  const renderContent = () => {
    return (
      <Box sx={{
        gap: 2,
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
      }}>
        {/* Reset to Design Defaults Button */}
        <Button
          startIcon={<RestoreIcon />}
          onClick={handleResetToDefaults}
          variant="outlined"
          color="secondary"
          size="small"
          sx={{ mb: 2 }}
        >
          Reset to Design Defaults
        </Button>

        {/* Global Typography Settings */}
        {renderGlobalSection()}

        {/* Section-specific Typography Settings */}
        {textSections.map(section => {
          const sectionContent = (
            <>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ mt: 1, color: theme.palette.text.secondary }}
              >
                Font Size
              </Typography>
              <PerformanceSlider
                name={`${section}-fontSize`}
                value={localSettings[section].fontSize}
                onChange={(val) => handleFontSizeChange(section, val)}
                min={10}
                max={section === 'heading' ? 40 : 24}
                onCommit={(val) => handleFontSizeChange(section, val)}
              />

              {/* Line height (except for small text) */}
              {section !== 'small' && (
                <>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ mt: 2, color: theme.palette.text.secondary }}
                  >
                    Line Height
                  </Typography>
                  <PerformanceSlider
                    name={`${section}-lineHeight`}
                    value={localSettings[section].lineHeight}
                    onChange={(val) => handleLineHeightChange(section, val)}
                    min={1}
                    max={2.5}
                    step={0.1}
                    onCommit={(val) => handleLineHeightChange(section, val)}
                  />
                </>
              )}

              <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                <InputLabel id={`${section}-font-family-label`}>Font Family</InputLabel>
                <Select
                  key={`${section}-font-family-${settingsKey}`}
                  labelId={`${section}-font-family-label`}
                  id={`${section}-font-family-select`}
                  value={localSettings[section].fontFamily}
                  label="Font Family"
                  onChange={(e) => handleFontFamilyChange(section, e)}
                >
                  {fontFamilies.map((font) => (
                    <MenuItem
                      key={font}
                      value={font}
                      sx={{ fontFamily: font }}
                    >
                      {font.split(',')[0].replace(/'/g, '')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              
              {section === 'heading'}
            </>
          );

          return renderSection(
            section,
            `${section.charAt(0).toUpperCase() + section.slice(1)} Text`,
            sectionContent
          );
        })}
      </Box>
    );
  };

  // For desktop embedded mode: render directly
  return (
    <Box sx={{ overflowY: 'auto' }}>
      {renderContent()}
    </Box>
  );
}

export default TypographySettings;