// src/components/InputSection/cards/EducationCard.js
import React from 'react';
import { Grid, TextField } from '@mui/material';
import { DateField, RichTextField } from '../shared/FormFields';
import SectionCardFactory from '../shared/SectionCardFactory';
import components from '../../../theme/components';

const EducationCard = SectionCardFactory({
  sectionName: "Education",
  sectionKey: "educations",
  
  initialItemFields: () => ({
    school: '',
    degree: '',
    fieldOfStudy: '',
    startDate: null,
    endDate: null,
    description: '',
    expanded: true,
  }),
  
  generateTitle: (item, index) => 
    item.school 
      ? `${item.school}${item.degree ? ` - ${item.degree}` : ''}`
      : `Education ${index + 1}`,
  
  renderFields: ({ item, handleChange }) => (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="School"
          value={item.school}
          onChange={(e) => handleChange('school', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Degree"
          value={item.degree}
          onChange={(e) => handleChange('degree', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Field of Study"
          value={item.fieldOfStudy}
          onChange={(e) => handleChange('fieldOfStudy', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField
          label="Start Date"
          value={item.startDate}
          onChange={(date) => handleChange('startDate', date)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField
          label="End Date"
          value={item.endDate}
          onChange={(date) => handleChange('endDate', date)}
        />
      </Grid>
      <Grid item xs={12}>
        <RichTextField
          value={item.description}
          onChange={(value) => handleChange('description', value)}
        />
      </Grid>
    </>
  )
});

export default EducationCard;
