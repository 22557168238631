// src/components/InputSection/cards/ProjectsCard.js
import React from 'react';
import { Grid, TextField } from '@mui/material';
import { DateField, RichTextField } from '../shared/FormFields';
import SectionCardFactory from '../shared/SectionCardFactory';
import components from '../../../theme/components';

const ProjectsCard = SectionCardFactory({
  sectionName: "Projects",
  sectionKey: "projects",
  
  initialItemFields: () => ({
    name: '',
    technologies: '',
    link: '',
    startDate: null,
    endDate: null,
    description: '',
    expanded: true,
  }),
  
  generateTitle: (item, index) => 
    item.name 
      ? `${item.name}${item.technologies ? ` - ${item.technologies}` : ''}`
      : `Project ${index + 1}`,
  
  renderFields: ({ item, handleChange }) => (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Project Name"
          value={item.name}
          onChange={(e) => handleChange('name', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Technologies Used"
          value={item.technologies}
          onChange={(e) => handleChange('technologies', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Project Link"
          value={item.link}
          onChange={(e) => handleChange('link', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField
          label="Start Date"
          value={item.startDate}
          onChange={(date) => handleChange('startDate', date)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField
          label="End Date"
          value={item.endDate}
          onChange={(date) => handleChange('endDate', date)}
        />
      </Grid>
      <Grid item xs={12}>
        <RichTextField
          value={item.description}
          onChange={(value) => handleChange('description', value)}
        />
      </Grid>
    </>
  )
});

export default ProjectsCard;
