import React, { useContext } from "react";
import { IconButton } from "@mui/material";
import { LightMode, DarkMode } from "@mui/icons-material";
import tokens from "../../theme/tokens";
import { ThemeContext } from "../../context/ThemeContext";

// LightDarkMode component
function LightDarkMode() {
    const { isDarkMode, toggleDarkMode } = useContext(ThemeContext);

    return (
        <IconButton
            onClick={toggleDarkMode}
            sx={{
                color: tokens.colors.neutral[isDarkMode ? 300 : 600],
                '&:hover': {
                    color: tokens.colors.neutral[isDarkMode ? 100 : 800],
                    backgroundColor: 'transparent',
                },
            }}
        >
            {isDarkMode ? <LightMode /> : <DarkMode />}
        </IconButton>);
}

export default LightDarkMode;