import React from 'react';
import { useLayout } from '../context/LayoutContext';
import { useDesign } from '../context/DesignContext';
import { useTypography } from '../context/TypographyContext';
import { getLayoutComponent } from './LayoutRegistry';
import { BaseLayout } from './BaseLayout';

/**
 * LayoutResolver component dynamically renders the selected layout
 * based on the current layout setting from LayoutContext
 */
const LayoutResolver = ({ 
  formData, 
  selectedSections, 
  currentPage = 0, 
  totalPages = 1,
  measureMode = false, 
  isPdfExport = false,
  onRenderComplete,
  // Optional direct props for use in PDF export
  selectedLayout: directSelectedLayout,
  activeDesign: directActiveDesign,
  typographySettings: directTypographySettings
}) => {
  // Use context values unless direct props are provided (e.g., for PDF export)
  const layoutContext = useLayout();
  const designContext = useDesign();
  const typographyContext = useTypography();
  
  const selectedLayout = directSelectedLayout || layoutContext.selectedLayout;
  const activeDesign = directActiveDesign || designContext.activeDesign;
  const typographySettings = directTypographySettings || typographyContext.typographySettings;

  // Get the selected layout component 
  const SelectedLayout = getLayoutComponent(selectedLayout);

  return (
    <SelectedLayout
      key={`layout-${selectedLayout}-${BaseLayout.getTypographyKey(typographySettings)}`}
      formData={formData}
      selectedSections={selectedSections}
      design={activeDesign}
      typographySettings={typographySettings}
      currentPage={currentPage}
      totalPages={totalPages}
      measureMode={measureMode}
      isPdfExport={isPdfExport}
      onRenderComplete={onRenderComplete}
    />
  );
};

export default LayoutResolver; 