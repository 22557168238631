import BlueDesign from './BlueDesign';
import GreenDesign from './GreenDesign';
import OrangeDesign from './OrangeDesign';
import ColorfulDesign from './ColorfulDesign';
import GradientDesign from './GradientDesign';

// Registry for all designs
const DESIGNS = {
  'blue': BlueDesign,
  'green': GreenDesign,
  'orange': OrangeDesign,
  'colorful': ColorfulDesign,
  'gradient': GradientDesign,
};

// Get all designs as an array
export const getAllDesigns = () => {
  return Object.values(DESIGNS);
};

// Get a specific design by ID
export const getDesignById = (id) => {
  return DESIGNS[id] || DESIGNS['blue']; // Fallback to blue design
};

export default {
  getAllDesigns,
  getDesignById,
  DESIGNS
}; 