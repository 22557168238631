// src/context/FormContext.js

import React, { createContext, useState, useCallback } from 'react';
import { TypographyContext } from './TypographyContext';

// Create the context
export const FormContext = createContext();

// Default form data structure
const defaultFormData = {
  personalInfo: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    photo: '',
    linkedin: '',
    github: '',
  },
  professionalSummary: '',
  workExperiences: [],
  educations: [],
  skills: [],
  projects: [],
  certifications: [],
  awardsAndHonors: [],
  languages: [],
  volunteerExperiences: [],
  publications: [],
  references: [],
};

// Provider component with optimizations
export function FormProvider({ children }) {
  const [formData, setFormData] = useState(defaultFormData);
  
  // Memoize the updateFormData function to prevent unnecessary re-renders
  const updateFormData = useCallback((sectionKey, data) => {
    if (sectionKey === null) {
      // Handle JSON import case
      setFormData(prevData => {
        // Current implementation
        const safeData = {};
        
        // Iterate through each key in the default structure
        Object.keys(defaultFormData).forEach(key => {
          if (typeof defaultFormData[key] === 'object' && !Array.isArray(defaultFormData[key])) {
            // For nested objects like personalInfo
            safeData[key] = { 
              ...defaultFormData[key], // Start with defaults
              ...(data[key] || {}) // Safely overlay imported data
            };
          } else if (Array.isArray(defaultFormData[key])) {
            // For arrays like workExperiences
            safeData[key] = Array.isArray(data[key]) ? data[key] : [];
          } else {
            // For simple fields like professionalSummary
            safeData[key] = data[key] !== undefined ? data[key] : defaultFormData[key];
          }
        });
        
        return safeData;
      });
      
      // Handle typography settings import
      if (data.typographySettings) {
        localStorage.setItem('importedTypographySettings', JSON.stringify(data.typographySettings));
        window.dispatchEvent(new CustomEvent('typographySettingsImported'));
      }
      
    } else {
      // Handle specific section update
      setFormData(prev => ({
        ...prev,
        [sectionKey]: data,
      }));
    }
  }, []);
  
  // Memoize the exportFormData function
  const exportFormData = useCallback(() => {
    // Get typography settings from localStorage for most current values
    let typographySettings = null;
    try {
      const savedSettings = localStorage.getItem('typographySettings');
      if (savedSettings) {
        typographySettings = JSON.parse(savedSettings);
      }
    } catch (error) {
      console.error('Error reading typography settings for export:', error);
    }
    
    // Return form data with typography settings included
    return {
      ...formData,
      typographySettings
    };
  }, [formData]);
  
  return (
    <FormContext.Provider value={{ formData, updateFormData, exportFormData }}>
      {children}
    </FormContext.Provider>
  );
}

export default FormProvider;
