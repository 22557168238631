// Green design based on Spotify's color palette
const GreenDesign = {
  id: 'green',
  name: 'Green Tech',
  description: 'Modern creative design with green accents',
  colors: {
    primary: "#1DB954",    // Spotify Green (main brand color)
    secondary: "#191414",  // Spotify Black
    tertiary: "#1ED760",   // Spotify Bright Green
    quaternary: "#535353", // Spotify Dark Gray
    accent1: "#CDF564",    // Spotify Lime
    accent2: "#509BF5",    // Spotify Light Blue
    accent3: "#4687FB",    // Spotify Blue
    accent4: "#2D46B9",    // Spotify Dark Blue
    accent5: "#AF2896",    // Spotify Purple
    accent6: "#F037A5",    // Spotify Pink
    accent7: "#B3B3B3",    // Spotify Light Gray
    accent8: "#FFFFFF",    // Spotify White
    text: "#191414",       // Spotify Black Text
    textSecondary: "#535353", // Spotify Secondary Text
    background: "#FFFFFF", // White background
    sidebarBackground: "#F8F8F8", // Light gray background
    border: "#EBEBEB",     // Spotify border color
  },
  typography: {
    heading: {
      fontSize: 28,
      fontFamily: "'Circular Std', 'Montserrat', sans-serif",
      lineHeight: 1.3,
    },
    subheading: {
      fontSize: 18,
      fontFamily: "'Circular Std', 'Montserrat', sans-serif",
    },
    body: {
      fontSize: 14,
      fontFamily: "'Montserrat', 'Segoe UI', sans-serif",
    },
    small: {
      fontSize: 12,
      fontFamily: "'Montserrat', 'Segoe UI', sans-serif",
    },
    globalFontSize: 14,
    globalLineHeight: 1.5,
  }
};

export default GreenDesign; 