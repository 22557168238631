// src/components/InputSection.js

import React, { useContext, useEffect } from 'react';
import { FormContext } from '../../context/FormContext';
import { Box, useMediaQuery, useTheme, Typography, Button } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AddIcon from '@mui/icons-material/Add';
import PersonalInfoCard from './cards/PersonalInfoCard';
import ProfessionalSummaryCard from './cards/ProfessionalSummaryCard';
import WorkExperienceCard from './cards/WorkExperienceCard';
import EducationCard from './cards/EducationCard';
import SkillsCard from './cards/SkillsCard';
import ProjectsCard from './cards/ProjectsCard';
import CertificationsCard from './cards/CertificationsCard';
import AwardsAndHonorsCard from './cards/AwardsAndHonorsCard';
import LanguagesCard from './cards/LanguagesCard';
import VolunteerExperienceCard from './cards/VolunteerExperienceCard';
import PublicationsCard from './cards/PublicationsCard';
import ReferencesCard from './cards/ReferencesCard';
import layout from '../../theme/layout';
import tokens from '../../theme/tokens';

export const allSections = [
  { name: 'Professional Summary', component: ProfessionalSummaryCard },
  { name: 'Work Experience', component: WorkExperienceCard },
  { name: 'Education', component: EducationCard },
  { name: 'Skills', component: SkillsCard },
  { name: 'Projects', component: ProjectsCard },
  { name: 'Certifications', component: CertificationsCard },
  { name: 'Awards and Honors', component: AwardsAndHonorsCard },
  { name: 'Languages', component: LanguagesCard },
  { name: 'Volunteer Experience', component: VolunteerExperienceCard },
  { name: 'Publications', component: PublicationsCard },
  { name: 'References', component: ReferencesCard },
];

function InputSection({ selectedSections, setSelectedSections }) {
  const { formData } = useContext(FormContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Effect to auto-select sections when importing data
  useEffect(() => {
    if (formData) {
      const sectionsToActivate = [];
      
      // For each section type, check if there's data and it's not already selected
      if (formData.professionalSummary && 
          !selectedSections.some(s => s.name === 'Professional Summary')) {
        sectionsToActivate.push(allSections.find(s => s.name === 'Professional Summary'));
      }
      
      if (formData.workExperiences?.length > 0 && 
          !selectedSections.some(s => s.name === 'Work Experience')) {
        sectionsToActivate.push(allSections.find(s => s.name === 'Work Experience'));
      }
      
      if (formData.educations?.length > 0 && 
          !selectedSections.some(s => s.name === 'Education')) {
        sectionsToActivate.push(allSections.find(s => s.name === 'Education'));
      }
      
      // Continue for all other section types...
      
      // If any sections were detected with data, add them
      if (sectionsToActivate.length > 0) {
        setSelectedSections(prev => [...prev, ...sectionsToActivate]);
      }
    }
  }, [formData, selectedSections, setSelectedSections]);

  // Calculate unselectedSections based on selectedSections
  const unselectedSections = allSections.filter(
    (section) => !selectedSections.some((selected) => selected.name === section.name)
  );

  const { updateFormData } = useContext(FormContext);

  const handleSelectSection = (sectionName) => {
    const sectionToSelect = allSections.find((section) => section.name === sectionName);
    if (sectionToSelect) {
      // When adding a new section, it starts expanded
      setSelectedSections([...selectedSections, {
        ...sectionToSelect,
        expanded: true
      }]);
    }
  };

  const handleRemoveSection = (sectionName) => {
    const sectionKey = getSectionKeyByName(sectionName);
    setSelectedSections(
      selectedSections.filter((section) => section.name !== sectionName)
    );
    updateFormData(sectionKey, sectionKey === 'professionalSummary' ? '' : []);
  };

  // Helper function to map section names to keys in formData
  const getSectionKeyByName = (name) => {
    switch (name) {
      case 'Professional Summary':
        return 'professionalSummary';
      case 'Work Experience':
        return 'workExperiences';
      case 'Education':
        return 'educations';
      case 'Skills':
        return 'skills';
      case 'Projects':
        return 'projects';
      case 'Certifications':
        return 'certifications';
      case 'Awards and Honors':
        return 'awardsAndHonors';
      case 'Languages':
        return 'languages';
      case 'Volunteer Experience':
        return 'volunteerExperiences';
      case 'Publications':
        return 'publications';
      case 'References':
        return 'references';
      default:
        return '';
    }
  };

  // Handle drag end event for selected sections
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(selectedSections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSelectedSections(items);
  };

  return (
    <Box sx={{
      ...layout.mainContent,
      backgroundColor: 'transparent',
      padding: isMobile ? tokens.spacing[2] : layout.mainContent.padding,
      scrollbarWidth: 'none',  // Firefox
      msOverflowStyle: 'none', // IE and Edge
      '&::-webkit-scrollbar': { // Chrome, Safari, newer Edge
        display: 'none',
        width: 0,
        height: 0
      }
    }}>
      <PersonalInfoCard />

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="selectedSectionsDroppable">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {selectedSections.map((section, index) => {
                const SectionComponent = section.component;
                
                if (!SectionComponent) return null;

                return (
                  <Draggable 
                    key={section.name} 
                    draggableId={section.name} 
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <Box
                          sx={{
                            '&:not(:last-child)': {
                              borderBottom: `1px solid ${tokens.colors.neutral[200]}`,
                            }
                          }}
                        >
                          <SectionComponent
                            onRemove={() => handleRemoveSection(section.name)}
                            dragHandle={provided.dragHandleProps}
                            isSelected={true}
                          />
                        </Box>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* Section to add new content */}
      {unselectedSections.length > 0 && (
        <Box sx={{ mt: 3, p: 2, borderTop: `1px solid ${tokens.colors.neutral[200]}` }}>
          <Typography variant="h6" gutterBottom>
            Add Sections
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {unselectedSections.map((section) => (
              <Button
                key={section.name}
                variant="outlined"
                size="small"
                startIcon={<AddIcon />}
                onClick={() => handleSelectSection(section.name)}
                sx={{ mb: 1 }}
              >
                {section.name}
              </Button>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default InputSection;
