// Blue design based on Meta's color palette
const BlueDesign = {
  id: 'blue',
  name: 'Blue Tech',
  description: 'Clean professional design with blue accents',
  colors: {
    primary: "#1877F2",    // Meta blue
    secondary: "#0668E1",  // Darker blue
    tertiary: "#42B72A",   // Meta green
    quaternary: "#3578E5", // Another Meta blue shade
    accent1: "#00B2FF",    // Light blue
    accent2: "#0081FB",    // Medium blue
    accent3: "#1195F5",    // Another blue shade
    accent4: "#0064E0",    // Deep blue
    accent5: "#0099FF",    // Bright blue
    accent6: "#0077E6",    // Royal blue
    accent7: "#0088FA",    // Vivid blue
    accent8: "#2D88FF",    // Sky blue
    text: "#050505",       // Dark text
    textSecondary: "#65676B", // Secondary text
    background: "#ffffff", // White background
    sidebarBackground: "#F0F2F5", // Light gray background
    border: "#CED0D4",     // Border color
  },
  typography: {
    heading: {
      fontSize: 28,
      fontFamily: "'Product Sans', 'Roboto', sans-serif",
      lineHeight: 1.4,
    },
    subheading: {
      fontSize: 18,
      fontFamily: "'Product Sans', 'Roboto', sans-serif",
    },
    body: {
      fontSize: 14,
      fontFamily: "'Roboto', 'Segoe UI', sans-serif",
    },
    small: {
      fontSize: 12,
      fontFamily: "'Roboto', 'Segoe UI', sans-serif",
    },
    globalFontSize: 11,
    globalLineHeight: 1.2,
  }
};

export default BlueDesign; 