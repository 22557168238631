// Design tokens - fundamental styling values
const tokens = {
  // Color palette
  colors: {
    primary: {
      main: '#3b82f6',
      light: '#60a5fa',
      dark: 'rgba(255,255,255,0.05)',
      contrast: '#ffffff',
    },
    secondary: {
      main: '#6366f1',
      light: '#818cf8',
      dark: '#4f46e5',
      contrast: '#ffffff',
    },
    text: {
      light: 'rgba(0, 0, 0, 0.5)',
      dark: '#ffffff',
    },
    neutral: {
      50: '#f8fafc',
      100: '#f1f5f9',
      200: '#e2e8f0',
      300: '#cbd5e1',
      400: '#94a3b8',
      500: '#64748b',
      600: '#475569',
      700: '#334155',
      800: '#1e293b',
      900: '#0f172a',
    },
    success: '#22c55e',
    warning: '#f59e0b',
    error: '#ef4444',
    background: {
      default: '#ffffff',
      paper: '#f8fafc',
      dark: 'rgba(255,255,255,0.05)',
    },
    borders: {
      default: 'rgba(0,0,0,0.1)',
      light: 'rgba(0, 0, 0, 0.1)',
      dark: 'rgba(255,255,255,0.1)',
    },
    shadows: {
      default: 'rgba(0,0,0,0.1)',
      light: 'rgba(0,0,0,0)',
      dark: 'rgba(0, 0, 0, 0)',
    },
  },

  // Typography
  typography: {
    fontFamily: {
      primary: '"Inter", -apple-system, BlinkMacSystemFont, sans-serif',
      mono: '"JetBrains Mono", monospace',
    },
    fontSizes: {
      xs: '0.75rem',    // 12px
      sm: '0.875rem',   // 14px
      base: '1rem',     // 16px
      lg: '1.125rem',   // 18px
      xl: '1.25rem',    // 20px
      '2xl': '1.5rem',  // 24px
      '3xl': '1.875rem' // 30px
    },
    fontWeights: {
      normal: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
    lineHeights: {
      tight: 1.25,
      normal: 1.5,
      relaxed: 1.75,
    },
  },

  // Spacing
  spacing: {
    0: '0',
    px: '1px',
    0.5: '0.125rem',
    1: '0.25rem',
    1.5: '0.375rem',
    2: '0.5rem',
    3: '0.75rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    8: '2rem',
    10: '2.5rem',
    12: '3rem',
    input: {
      height: '2.4rem',
      padding: '0.6rem',
      labelOffset: '0.3rem',
      marginBottom: '0.4rem',
      gap: '0.5rem',
      labelPadding: {
        left: '0.2rem',
      }
    }
  },

  // Border radius
  radius: {
    none: '0',
    sm: '0.125rem',
    base: '0.25rem',
    md: '0.375rem',
    lg: '0.5rem',
    xl: '0.75rem',
    '2xl': '1rem',
    full: '9999px',
  },

  // Shadows
  shadows: {
    sm: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
    base: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
    md: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
    lg: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
  },

  // Z-index
  zIndex: {
    behind: -1,
    base: 0,
    above: 1,
    dropdown: 1000,
    modal: 1300,
    toast: 1400,
    tooltip: 1500,
  },
};

export default tokens; 