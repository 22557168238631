// src/components/InputSection/cards/VolunteerExperienceCard.js
import React from 'react';
import { Grid, TextField } from '@mui/material';
import { DateField, RichTextField } from '../shared/FormFields';
import SectionCardFactory from '../shared/SectionCardFactory';
import components from '../../../theme/components';

const VolunteerExperienceCard = SectionCardFactory({
  sectionName: "Volunteer Experience",
  sectionKey: "volunteerExperiences",
  
  initialItemFields: () => ({
    organization: '',
    role: '',
    startDate: null,
    endDate: null,
    description: '',
    expanded: true,
  }),
  
  generateTitle: (item, index) => 
    item.organization 
      ? `${item.organization}${item.role ? ` - ${item.role}` : ''}`
      : `Volunteer Experience ${index + 1}`,
  
  renderFields: ({ item, handleChange }) => (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Organization"
          value={item.organization}
          onChange={(e) => handleChange('organization', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Role"
          value={item.role}
          onChange={(e) => handleChange('role', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField
          label="Start Date"
          value={item.startDate}
          onChange={(date) => handleChange('startDate', date)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField
          label="End Date"
          value={item.endDate}
          onChange={(date) => handleChange('endDate', date)}
        />
      </Grid>
      <Grid item xs={12}>
        <RichTextField
          value={item.description}
          onChange={(value) => handleChange('description', value)}
        />
      </Grid>
    </>
  )
});

export default VolunteerExperienceCard;
