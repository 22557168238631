import React, { useEffect } from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faAt,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  faGithub,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { makeStyles } from '@mui/styles';
import { BaseLayout } from './BaseLayout';

// Create the layout component
const CenteredHeaderLayout = ({
  formData,
  selectedSections,
  design,
  typographySettings,
  currentPage = 0,
  totalPages = 1,
  measureMode = false,
  isPdfExport = false,
  onRenderComplete,
}) => {
  // Call onRenderComplete once, after first mount
  useEffect(() => {
    if (typeof onRenderComplete === 'function') {
      onRenderComplete();
    }
  }, [onRenderComplete]);

  // Create styles for this layout
  const useStyles = makeStyles({
    container: {
      width: '210mm',
      minHeight: '297mm',
      padding: '15mm',
      boxSizing: 'border-box',
      backgroundColor: design?.colors?.background || '#ffffff',
      color: design?.colors?.text || '#000000',
      ...BaseLayout.getTypographyStyles(typographySettings),
      "& *": {
        maxWidth: "180mm",
        wordWrap: "break-word",
        overflowWrap: "break-word",
      }
    },
    header: {
      textAlign: 'center',
      marginBottom: '15px',
    },
    profilePhoto: {
      width: '100px',
      height: '100px',
      margin: '0 auto 10px',
      border: `3px solid ${design?.colors?.primary || '#000000'}`,
    },
    headerName: {
      color: design?.colors?.primary || '#000000',
      fontWeight: 'bold',
    },
    headerTitle: {
      color: design?.colors?.secondary || '#333333',
      marginTop: '5px',
    },
    contactSection: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: '15px',
      marginTop: '10px',
    },
    contactItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      color: design?.colors?.text || '#000000',
    },
    contactIcon: {
      color: design?.colors?.primary || '#000000',
      fontSize: '16px',
    },
    section: {
      marginBottom: '15px',
      pageBreakInside: 'avoid',
      breakInside: 'avoid',
    },
    sectionHeader: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      borderBottom: `2px solid transparent`,
      paddingBottom: '5px',
    },
    sectionIcon: {
      marginRight: '8px',
      color: design?.colors?.primary || '#000000',
    },
    sectionTitle: {
      color: design?.colors?.primary || '#000000',
    },
    sectionContent: {
      marginLeft: '25px',
    },
    sectionItem: {
      marginBottom: '16px',
    },
    itemTitle: {
      fontSize: `${(typographySettings?.body?.fontSize || 14) + 2}px`,
      fontFamily: typographySettings?.body?.fontFamily || 'Arial, sans-serif',
      fontWeight: 'bold',
      color: design?.colors?.text || '#000000',
      lineHeight: `${typographySettings?.body?.lineHeight || 1.5}`,
    },
    itemSubtitle: {
      fontSize: `${typographySettings?.body?.fontSize || 14}px`,
      fontFamily: typographySettings?.body?.fontFamily || 'Arial, sans-serif',
      color: design?.colors?.textSecondary || '#666666',
      marginTop: '2px',
      lineHeight: `${typographySettings?.body?.lineHeight || 1.5}`,
    },
    itemDescription: {
      fontSize: `${typographySettings?.body?.fontSize || 14}px`,
      fontFamily: typographySettings?.body?.fontFamily || 'Arial, sans-serif',
      color: design?.colors?.text || '#000000',
      marginTop: '4px',
      lineHeight: `${typographySettings?.body?.lineHeight || 1.5}`,
    },
    richText: {
      marginTop: '8px',
      fontSize: `${typographySettings?.body?.fontSize || 14}px`,
      fontFamily: typographySettings?.body?.fontFamily || 'Arial, sans-serif',
      lineHeight: `${typographySettings?.body?.lineHeight || 1.5}`,
      "& ul, & ol": {
        margin: "6px 0",
        paddingLeft: "20px",
        fontSize: `${typographySettings?.body?.fontSize || 14}px`,
        lineHeight: `${typographySettings?.body?.lineHeight || 1.5}`,
      },
      "& li": {
        marginBottom: "4px",
        fontSize: `${typographySettings?.body?.fontSize || 14}px`,
        lineHeight: `${typographySettings?.body?.lineHeight || 1.5}`,
      },
      "& p": {
        margin: "6px 0",
        fontSize: `${typographySettings?.body?.fontSize || 14}px`,
        lineHeight: `${typographySettings?.body?.lineHeight || 1.5}`,
      }
    },
    itemLink: {
      color: design?.colors?.primary || '#0000FF',
      display: 'block',
      marginTop: '5px',
      textDecoration: 'none',
      fontSize: `${typographySettings?.body?.fontSize || 14}px`,
      fontFamily: typographySettings?.body?.fontFamily || 'Arial, sans-serif',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    paragraph: {
      fontSize: `${typographySettings?.body?.fontSize || 14}px`,
      fontFamily: typographySettings?.body?.fontFamily || 'Arial, sans-serif',
      lineHeight: `${typographySettings?.body?.lineHeight || 1.5}`,
    },
  });
  
  // Get the styles with proper dependencies
  const classes = useStyles();

  // Get personal info from form data
  const { personalInfo = {} } = formData;

  return (
    <Box
      className={classes.container}
      sx={{
        // Apply measurement mode styles
        ...(measureMode ? {
          position: 'absolute',
          visibility: 'hidden'
        } : {}),
        // Apply PDF export styles
        ...(isPdfExport ? {
          position: 'relative',
          visibility: 'visible',
        } : {}),
      }}
    >
      {/* Header - Only on first page */}
      {currentPage === 0 && (
        <Box className={classes.header}>
          {/* Profile Photo */}
          {personalInfo.photo && (
            <Avatar
              src={personalInfo.photo}
              alt={`${personalInfo.firstName} ${personalInfo.lastName}`}
              className={classes.profilePhoto}
            />
          )}
          {/* Name */}
          {personalInfo.firstName && personalInfo.lastName && (
            <Typography className="headerName">
              {BaseLayout.safeRender(personalInfo.firstName)} {BaseLayout.safeRender(personalInfo.lastName)}
            </Typography>
          )}
          {/* Professional Title */}
          {personalInfo.professionalTitle && (
            <Typography className="headerTitle">
              {BaseLayout.safeRender(personalInfo.professionalTitle)}
            </Typography>
          )}
          {/* Contact Information */}
          <Box className={classes.contactSection}>
            {personalInfo.phone && (
              <Box className={classes.contactItem}>
                <FontAwesomeIcon icon={faPhone} className={classes.contactIcon} />
                {BaseLayout.safeRender(personalInfo.phone)}
              </Box>
            )}
            {personalInfo.email && (
              <Box className={classes.contactItem}>
                <FontAwesomeIcon icon={faAt} className={classes.contactIcon} />
                {BaseLayout.safeRender(personalInfo.email)}
              </Box>
            )}
            {personalInfo.linkedin && (
              <Box className={classes.contactItem}>
                <FontAwesomeIcon icon={faLinkedin} className={classes.contactIcon} />
                {BaseLayout.safeRender(personalInfo.linkedin)}
              </Box>
            )}
            {personalInfo.github && (
              <Box className={classes.contactItem}>
                <FontAwesomeIcon icon={faGithub} className={classes.contactIcon} />
                {BaseLayout.safeRender(personalInfo.github)}
              </Box>
            )}
            {personalInfo.address && (
              <Box className={classes.contactItem}>
                <FontAwesomeIcon icon={faMapMarkerAlt} className={classes.contactIcon} />
                {BaseLayout.safeRender(personalInfo.address)}
              </Box>
            )}
          </Box>
        </Box>
      )}

      {/* Render each section using BaseLayout's renderer functions */}
      {selectedSections.map((section) => {
        switch(section.name) {
          case 'Professional Summary':
            return BaseLayout.renderProfessionalSummary(formData, classes, design?.colors);
          case 'Work Experience':
            return BaseLayout.renderWorkExperiences(formData, classes, design?.colors);
          case 'Education':
            return BaseLayout.renderEducations(formData, classes, design?.colors);
          case 'Skills':
            return BaseLayout.renderSkills(formData, classes, design?.colors);
          case 'Projects':
            return BaseLayout.renderProjects(formData, classes, design?.colors);
          case 'Certifications':
            return BaseLayout.renderCertifications(formData, classes, design?.colors);
          case 'Awards and Honors':
            return BaseLayout.renderAwardsAndHonors(formData, classes, design?.colors);
          case 'Languages':
            return BaseLayout.renderLanguages(formData, classes, design?.colors);
          case 'Volunteer Experience':
            return BaseLayout.renderVolunteerExperiences(formData, classes, design?.colors);
          case 'Publications':
            return BaseLayout.renderPublications(formData, classes, design?.colors);
          case 'References':
            return BaseLayout.renderReferences(formData, classes, design?.colors);
          default:
            return null;
        }
      })}

      {/* Page number */}
      {totalPages > 1 && !measureMode && (
        <Typography
          sx={{
            position: 'absolute',
            bottom: '10mm',
            right: '10mm',
            fontSize: '10px',
            color: design?.colors?.textSecondary || '#666',
          }}
        >
          Page {currentPage + 1} of {totalPages}
        </Typography>
      )}
    </Box>
  );
};

export default CenteredHeaderLayout; 