import tokens from './tokens';

// Component-specific styling
const components = {
  // Form components
  input: {
    root: {
      backgroundColor: theme => theme.palette.mode === 'dark' 
        ? tokens.colors.background.dark 
        : tokens.colors.background.light,
      border: theme => `1px solid ${
        theme.palette.mode === 'dark' 
          ? tokens.colors.borders.dark
          : tokens.colors.borders.light
      }`,
      borderRadius: tokens.radius.base,
      height: tokens.spacing.input.height,
      transition: 'all 150ms ease',
      marginBottom: tokens.spacing.input.marginBottom,
      
      '& .MuiInputBase-root': {
        height: tokens.spacing.input.height,
        fontSize: tokens.typography.fontSizes.xs,
      },
      
      '& .MuiOutlinedInput-input': {
        padding: tokens.spacing.input.padding,
        fontSize: tokens.typography.fontSizes.xs,
      },
      
      '& .MuiInputLabel-root': {
        marginLeft: tokens.spacing.input.labelPadding.left,
        color: theme => theme.palette.mode === 'dark'
          ? tokens.colors.text.dark
          : tokens.colors.text.light,
        fontSize: tokens.typography.fontSizes.xs,
        fontWeight: tokens.typography.fontWeights.normal,
        lineHeight: tokens.typography.lineHeights.tight,
        '&.Mui-focused, &.MuiFormLabel-filled': {
          marginLeft: 0,
        }
      },
      
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme => theme.palette.mode === 'dark'
          ? tokens.colors.borders.dark
          : tokens.colors.borders.light,
      },
      
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme => theme.palette.mode === 'dark'
          ? tokens.colors.borders.dark
          : tokens.colors.borders.light,
      },
      
      '& .MuiInputBase-input': {
        color: theme => theme.palette.mode === 'dark'
          ? tokens.colors.text.dark
          : tokens.colors.text.light,
      },
    },
    label: {
      fontSize: tokens.typography.fontSizes.xs,
      fontWeight: tokens.typography.fontWeights.normal,
    },
  },

  // Cards
  card: {
    root: {
      backgroundColor: 'transparent',
      borderRadius: 0,
      border: 'none',
      borderBottom: theme => `1px solid ${
        theme.palette.mode === 'dark' 
          ? tokens.colors.borders.dark
          : tokens.colors.borders.light
      }`,
      boxShadow: 'none',
      transition: 'all 150ms ease',
      '&:hover': {
        backgroundColor: theme => theme.palette.mode === 'dark'
          ? tokens.colors.background.dark
          : tokens.colors.background.light,
      },
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    header: {
      padding: `${tokens.spacing[3]} ${tokens.spacing[4]}`,
      backgroundColor: 'transparent',
      borderBottom: tokens.colors.borders.default,
    },
    content: {
      padding: `0 ${tokens.spacing[4]} ${tokens.spacing[4]}`,
      backgroundColor: 'transparent',
    },
  },

  // Buttons
  button: {
    base: {
      borderRadius: tokens.radius.base,
      fontSize: tokens.typography.fontSizes.sm,
      fontWeight: tokens.typography.fontWeights.medium,
      padding: `${tokens.spacing[2]} ${tokens.spacing[4]}`,
      transition: 'all 150ms ease',
    },
    primary: {
      backgroundColor: tokens.colors.primary.main,
      color: tokens.colors.primary.contrast,
      '&:hover': {
        backgroundColor: tokens.colors.primary.dark,
      },
    },
    secondary: {
      backgroundColor: tokens.colors.neutral[200],
      color: tokens.colors.neutral[700],
      '&:hover': {
        backgroundColor: tokens.colors.neutral[300],
      },
    },
  },

  // Container styles
  container: {
    root: {
      backgroundColor: tokens.colors.background.paper,
      borderRadius: tokens.radius.lg,
      padding: tokens.spacing[4],
    },
  },

  // IconButton styles
  iconButton: {
    root: {
      color: tokens.colors.neutral[400],
      '&:hover': {
        color: tokens.colors.neutral[600],
        backgroundColor: 'transparent',
      },
    },
  },

  // Rich text editor styles
  richText: {
    root: {
      '& .ql-toolbar': {
        border: theme => `1px solid ${
          theme.palette.mode === 'dark'
            ? tokens.colors.borders.dark
            : tokens.colors.borders.light
        }`,
        borderBottom: 'none',
        backgroundColor: theme => theme.palette.mode === 'dark'
          ? tokens.colors.background.dark
          : tokens.colors.background.light,
      },
      '& .ql-container': {
        border: theme => `1px solid ${
          theme.palette.mode === 'dark'
            ? tokens.colors.borders.dark
            : tokens.colors.borders.light
        }`,
        backgroundColor: theme => theme.palette.mode === 'dark'
          ? tokens.colors.background.dark
          : tokens.colors.background.light,
        minHeight: '150px',
      },
      '& .ql-editor': {
        color: theme => theme.palette.mode === 'dark'
          ? '#ffffff'
          : 'inherit',
        backgroundColor: theme => theme.palette.mode === 'dark'
          ? tokens.colors.background.dark
          : tokens.colors.background.light,
      },
      '& .ql-editor.ql-blank::before': {
        color: theme => theme.palette.mode === 'dark'
          ? tokens.colors.text.dark
          : tokens.colors.text.light,
        fontStyle: 'normal',
      },
      '& .ql-stroke': {
        stroke: theme => theme.palette.mode === 'dark'
          ? '#ffffff'
          : '#000000',
      },
      '& .ql-fill': {
        fill: theme => theme.palette.mode === 'dark'
          ? '#ffffff'
          : '#000000',
      },
      '& .ql-picker': {
        color: theme => theme.palette.mode === 'dark'
          ? '#ffffff'
          : 'inherit',
      },
    },
  },

  // Add styles for multiline text fields
  textField: {
    root: {
      '& .MuiOutlinedInput-root': {
        backgroundColor: theme => theme.palette.mode === 'dark'
          ? 'rgba(255,255,255,0.05)'
          : 'transparent',
        '& textarea': {
          color: theme => theme.palette.mode === 'dark'
            ? '#ffffff'
            : 'inherit',
        },
      },
    },
  },
};

export default components; 