import React, { useState } from 'react';
import { Box, IconButton, Paper, Collapse, Typography, useMediaQuery } from '@mui/material';
import { DragIndicator, ExpandLess, ExpandMore, Remove } from '@mui/icons-material';
import tokens from '../../../theme/tokens';
import components from '../../../theme/components';
import { useTheme } from '@mui/material/styles';

function SubCard({ item, index, provided, removeItem, children, title }) {
  const [expanded, setExpanded] = useState(item.expanded || true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleExpandToggle = () => {
    setExpanded(!expanded);
    // If you need to persist the expanded state, you would update the item here
  };

  return (
    <Paper
      ref={provided.innerRef}
      {...provided.draggableProps}
      elevation={0}
      sx={{
        ...components.card.root,
        mb: tokens.spacing[2],
        mt: tokens.spacing[1],
        backgroundColor: theme => theme.palette.mode === 'dark' 
          ? tokens.colors.background.dark 
          : tokens.colors.background.paper,
        border: theme => `1px solid ${
          theme.palette.mode === 'dark'
            ? tokens.colors.borders.dark
            : tokens.colors.borders.light
        }`,
        '&:hover': {
          backgroundColor: theme => theme.palette.mode === 'dark'
            ? tokens.colors.background.dark
            : tokens.colors.background.light,
        },
        transition: 'all 150ms ease',
      }}
    >
      {/* Header Section */}
      <Box
        sx={{
          ...components.card.header,
          display: 'flex',
          alignItems: 'center',
          p: isMobile ? tokens.spacing[1] : tokens.spacing[2],
          cursor: 'pointer',
          '&:hover': {
            bgcolor: theme => theme.palette.mode === 'dark'
              ? tokens.colors.background.dark
              : tokens.colors.background.light,
          },
        }}
        onClick={handleExpandToggle}
      >
        <Box 
          {...provided.dragHandleProps}
          sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: tokens.spacing[2],
          }}
        >
          <DragIndicator sx={{ 
            color: tokens.colors.neutral[400],
            cursor: 'grab',
          }} />
          <Typography 
            variant="subtitle2"
            sx={{ 
              fontSize: tokens.typography.fontSizes.sm,
              fontWeight: tokens.typography.fontWeights.medium,
              color: theme => theme.palette.mode === 'dark' 
                ? 'text.primary' 
                : 'text.secondary',
            }}
          >
            {title || `Item ${index + 1}`}
          </Typography>
        </Box>

        <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              removeItem(item.id);
            }}
            size="small"
            sx={{
              color: tokens.colors.neutral[400],
              '&:hover': {
                color: tokens.colors.neutral[600],
                backgroundColor: 'transparent',
              },
              // Increase touch target size for mobile
              padding: isMobile ? tokens.spacing[1] : undefined,
              minWidth: isMobile ? '48px' : undefined,
              minHeight: isMobile ? '48px' : undefined,
            }}
          >
            <Remove fontSize="small" />
          </IconButton>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleExpandToggle();
            }}
            size="small"
            sx={{
              color: tokens.colors.neutral[400],
              '&:hover': {
                color: tokens.colors.neutral[600],
                backgroundColor: 'transparent',
              },
              // Increase touch target size for mobile
              padding: isMobile ? tokens.spacing[1] : undefined,
              minWidth: isMobile ? '48px' : undefined,
              minHeight: isMobile ? '48px' : undefined,
            }}
          >
            {expanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
          </IconButton>
        </Box>
      </Box>

      {/* Content Section */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box sx={{ 
          p: isMobile ? tokens.spacing[1] : tokens.spacing[2],
        }}>
          {children}
        </Box>
      </Collapse>
    </Paper>
  );
}

export default SubCard; 