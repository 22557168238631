import React, { useState } from 'react';
import { 
  Box, 
  Tabs, 
  Tab, 
  Typography,
  Paper,
  useTheme,
  useMediaQuery,
  IconButton
} from '@mui/material';
import LayoutSelector from './LayoutSelector';
import DesignSelector from './DesignSelector';
import TypographySettings from '../typography/TypographySettings';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import PaletteIcon from '@mui/icons-material/Palette';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function SettingsPanel({ isCollapsed, toggleCollapse }) {
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // If collapsed, only show the expand button
  if (isCollapsed) {
    return (
      <Box 
        sx={{ 
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderLeft: `1px solid ${theme.palette.divider}`,
        }}
      >
        <IconButton 
          onClick={toggleCollapse}
          size="small"
          sx={{ 
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[2],
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            }
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      </Box>
    );
  }

  return (
    <Paper 
      elevation={0}
      sx={{ 
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 0,
        border: `1px solid ${theme.palette.divider}`,
        borderTop: 'none',
        borderRight: 'none',
        borderBottom: 'none',
        position: 'relative', // For collapse button positioning
      }}
    >
      {/* Collapse button */}
      <IconButton
        onClick={toggleCollapse}
        size="small"
        sx={{
          position: 'absolute',
          top: '50%',
          left: -16,
          zIndex: 10,
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[2],
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          }
        }}
      >
        <ChevronRightIcon />
      </IconButton>

      {/* Tab Navigation */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange}
          variant="fullWidth"
          sx={{
            '.MuiTabs-indicator': {
              backgroundColor: theme.palette.primary.main,
              height: 3,
            },
            '.MuiTab-root': {
              textTransform: 'none',
              minHeight: '48px',
              fontSize: '0.9rem',
              fontWeight: theme.typography.fontWeightMedium,
              transition: 'all 0.2s',
              paddingLeft: 2,
              paddingRight: 2,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
              '&.Mui-selected': {
                color: theme.palette.primary.main,
                fontWeight: theme.typography.fontWeightBold,
              }
            }
          }}
        >
          <Tab 
            icon={<ViewQuiltIcon />} 
            label="Layout" 
            iconPosition="start"
            sx={{ py: 1.5 }}
          />
          <Tab 
            icon={<PaletteIcon />} 
            label="Design" 
            iconPosition="start"
            sx={{ py: 1.5 }}
          />
          <Tab 
            icon={<TextFormatIcon />} 
            label="Typography" 
            iconPosition="start"
            sx={{ py: 1.5 }}
          />
        </Tabs>
      </Box>
      
      {/* Tab Content */}
      <Box sx={{ 
        p: 2, 
        flex: 1, 
        overflow: 'auto',
        backgroundColor: theme.palette.background.default,
      }}>
        {/* Layout Tab */}
        {activeTab === 0 && (
          <LayoutSelector />
        )}
        
        {/* Design Tab */}
        {activeTab === 1 && (
          <DesignSelector />
        )}
        
        {/* Typography Tab */}
        {activeTab === 2 && (
          <TypographySettings />
        )}
      </Box>
    </Paper>
  );
}

export default SettingsPanel; 