import tokens from './tokens';

// Layout configurations
export const layout = {
  container: {
    maxWidth: '1280px',
    padding: `0 ${tokens.spacing[4]}`,
    margin: '0 auto',
  },

  sidebar: {
    width: '100px',
    maxHeight: '100vh',
    padding: tokens.spacing[4],
    backgroundColor: tokens.colors.background.paper,
    borderRight: `1px solid ${tokens.colors.neutral[200]}`,
  },

  mainContent: {
    flex: 1,
    padding: tokens.spacing[4],
  },

  grid: {
    spacing: tokens.spacing[4],
    columns: {
      sm: 1,
      md: 2,
      lg: 3,
    },
  },

  section: {
    marginBottom: tokens.spacing[6],
    header: {
      marginBottom: tokens.spacing[4],
    },
  },
};

export default layout; 