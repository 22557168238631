import React from 'react';
import { Grid, TextField } from '@mui/material';
import SectionCardFactory from '../shared/SectionCardFactory';
import components from '../../../theme/components';

const ReferencesCard = SectionCardFactory({
  sectionName: "References",
  sectionKey: "references",
  
  initialItemFields: () => ({
    name: '',
    position: '',
    company: '',
    email: '',
    phone: '',
    expanded: true,
  }),
  
  generateTitle: (item, index) => 
    item.name 
      ? `${item.name}${item.position && item.company ? ` - ${item.position} at ${item.company}` : ''}`
      : `Reference ${index + 1}`,
  
  renderFields: ({ item, handleChange }) => (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Reference Name"
          value={item.name}
          onChange={(e) => handleChange('name', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Position"
          value={item.position}
          onChange={(e) => handleChange('position', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Company"
          value={item.company}
          onChange={(e) => handleChange('company', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Email"
          value={item.email}
          onChange={(e) => handleChange('email', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Phone"
          value={item.phone}
          onChange={(e) => handleChange('phone', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
    </>
  )
});

export default ReferencesCard; 