import CenteredHeaderLayout from './CenteredHeaderLayout';
import RightAlignedHeaderLayout from './RightAlignedHeaderLayout';
import LeftAlignedHeaderLayout from './LeftAlignedHeaderLayout';
import SidebarLeftLayout from './SidebarLeftLayout';
import SidebarRightLayout from './SidebarRightLayout';

// Registry of all available layouts
const LAYOUTS = {
  'centered-header': {
    id: 'centered-header',
    name: 'Centered',
    description: 'Professional layout with centered header',
    component: CenteredHeaderLayout
  },
  'right-aligned-header': {
    id: 'right-aligned-header',
    name: 'Right-Aligned',
    description: 'Modern layout with right-aligned header',
    component: RightAlignedHeaderLayout
  },
  'left-aligned-header': {
    id: 'left-aligned-header',
    name: 'Left-Aligned',
    description: 'Modern layout with left-aligned header',
    component: LeftAlignedHeaderLayout
  },
  'sidebar-left': {
    id: 'sidebar-left',
    name: 'Sidebar Left',
    description: 'Layout with skills and contact in left sidebar',
    component: SidebarLeftLayout
  },
  'sidebar-right': {
    id: 'sidebar-right',
    name: 'Sidebar Right',
    description: 'Layout with skills and contact in right sidebar',
    component: SidebarRightLayout
  }
};

// Get all layouts as an array with their metadata
export const getAllLayouts = () => {
  return Object.values(LAYOUTS);
};

// Get a specific layout by ID
export const getLayoutById = (id) => {
  return LAYOUTS[id] || LAYOUTS['centered-header']; // Default to centered header
};

// Get just the component for a layout ID
export const getLayoutComponent = (id) => {
  const layout = getLayoutById(id);
  return layout.component;
};

export default {
  getAllLayouts,
  getLayoutById,
  getLayoutComponent,
  LAYOUTS
}; 