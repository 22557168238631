// src/App.js

import React, { useState, useContext, useMemo, useRef } from "react";
import {
  Box,
  CssBaseline,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { ThemeProvider as CustomThemeProvider } from './context/ThemeContext';
import { PaginationProvider } from './context/PaginationContext';
import { FormProvider } from "./context/FormContext";
import { TypographyProvider } from './context/TypographyContext';
import { LayoutProvider } from './context/LayoutContext';
import { DesignProvider } from './context/DesignContext';
import InputSection from "./components/InputSection/InputSection";
import LivePreview from "./components/PreviewSection/LivePreview";
import TypographySettings from './typography/TypographySettings';
import Header from './components/Header';
import { ThemeContext } from './context/ThemeContext';
import { getTheme } from './theme';
import MobileNavBar from './components/MobileNavBar';
import DesignLayoutPicker from "./components/DesignLayoutPicker";
import DebugComponent from './components/DebugComponent';
import SettingsPanel from './components/SettingsPanel';
import GlobalStyles from './components/GlobalStyles';

// Main content component with state management
function AppContent() {
  // Component state
  const [selectedSections, setSelectedSections] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [typographyDialogOpen, setTypographyDialogOpen] = useState(false);
  const [designLayoutPickerOpen, setDesignLayoutPickerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const previewRef = useRef(null);
  const [isPanelCollapsed, setIsPanelCollapsed] = useState(false);
  
  // Theme and responsive utilities
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isDarkMode } = useContext(ThemeContext);

  // Open typography settings modal
  const openTypographySettings = () => {
    setTypographyDialogOpen(true);
  };

  // Open design and layout picker
  const openDesignLayoutPicker = () => {
    setDesignLayoutPickerOpen(true);
  };

  // Toggle panel collapsed state
  const togglePanelCollapse = () => {
    setIsPanelCollapsed(!isPanelCollapsed);
  };
  
  // Calculate widths based on panel state
  // Input section is always 30%, preview section expands when settings panel collapses
  const leftColumnWidth = isMobile ? '100%' : '30%'; // Always 30% on desktop
  const middleColumnWidth = isMobile ? '100%' : (isPanelCollapsed ? '68%' : '50%');
  const rightColumnWidth = isPanelCollapsed ? '2%' : '20%';

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      height: '100vh',
      backgroundColor: theme.palette.background.default 
    }}>
      {/* Header with app title and primary actions */}
      <Header 
        selectedSections={selectedSections}
        setSelectedSections={setSelectedSections}
        previewRef={previewRef}
        setShowPreview={setShowPreview}
        openTypographySettings={openTypographySettings}
        openDesignLayoutPicker={openDesignLayoutPicker}
      />

      {/* Main content area - Three columns on desktop, stacked on mobile */}
      <Box sx={{ 
        display: 'flex', 
        flexDirection: isMobile ? 'column' : 'row',
        flex: 1,
        overflow: 'hidden'
      }}>
        {/* Left column - Input section */}
        <Box 
          sx={{ 
            width: leftColumnWidth,
            display: (isMobile && showPreview) ? 'none' : 'block',
            height: isMobile ? 'calc(100vh - 56px - 56px)' : '100%', // Adjust for header and navbar
            overflow: 'auto',
            borderRight: `1px solid ${theme.palette.divider}`,
            transition: 'width 0.3s ease', // Smooth width transition
          }}
        >
          <Box sx={{ padding: 2 }}>
            <InputSection
              selectedSections={selectedSections}
              setSelectedSections={setSelectedSections}
            />
          </Box>
        </Box>

        {/* Middle column - Preview */}
        <Box 
          sx={{ 
            width: middleColumnWidth,
            display: (isMobile && !showPreview) ? 'none' : 'block',
            height: isMobile ? 'calc(100vh - 56px - 56px)' : '100%', // Adjust for header and navbar
            overflow: 'hidden',
            borderRight: `1px solid ${theme.palette.divider}`,
            position: 'relative',
            transition: 'width 0.3s ease', // Smooth width transition
          }}
          ref={previewRef}
        >
          {loading && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 10,
                backgroundColor: 'rgba(255,255,255,0.7)',
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <LivePreview 
            selectedSections={selectedSections}
            setSelectedSections={setSelectedSections}
          />
        </Box>

        {/* Right column - Settings Panel (with tabs) - collapsible */}
        {!isMobile && (
          <Box 
            sx={{ 
              width: rightColumnWidth,
              height: '100%', 
              overflow: 'hidden',
              display: { xs: 'none', md: 'block' },
              transition: 'width 0.3s ease', // Smooth width transition
            }}
          >
            <SettingsPanel 
              isCollapsed={isPanelCollapsed}
              toggleCollapse={togglePanelCollapse}
            />
          </Box>
        )}
      </Box>

      {/* Mobile Navigation Bar */}
      {isMobile && (
        <MobileNavBar
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          openTypographySettings={openTypographySettings}
          openDesignLayoutPicker={openDesignLayoutPicker}
          previewRef={previewRef}
          setSelectedSections={setSelectedSections}
          selectedSections={selectedSections}
        />
      )}

      {/* No need for mobile-specific dialogs here as they're handled in MobileNavBar */}
    </Box>
  );
}

// Theme consumer component to wrap app content
function ThemeConsumer() {
  const { isDarkMode } = useContext(ThemeContext);
  const theme = useMemo(() => getTheme(isDarkMode), [isDarkMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <FormProvider>
        <LayoutProvider>
          <DesignProvider>
            <PaginationProvider>
              <TypographyProvider>
                <AppContent />
              </TypographyProvider>
            </PaginationProvider>
          </DesignProvider>
        </LayoutProvider>
      </FormProvider>
    </ThemeProvider>
  );
}

// Main App component
function App() {
  return (
    <CustomThemeProvider>
        <GlobalStyles />
        <DebugComponent />
        <ThemeConsumer />
    </CustomThemeProvider>
  );
}

export default App;
