import React, { useState, useContext } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Collapse,
  IconButton,
  Typography,
  Box,
  useMediaQuery,
} from '@mui/material';
import { ExpandLess, ExpandMore, Remove, DragIndicator, AddCircleOutline } from '@mui/icons-material';
import { ThemeContext } from '../../../context/ThemeContext';
import tokens from '../../../theme/tokens';
import components from '../../../theme/components';
import { useTheme } from '@mui/material/styles';

function BaseCard({
  title,
  isSelected,
  onRemove,
  onSelect,
  dragHandle,
  children,
  defaultExpanded = true,
  showRemoveButton = true,
  expanded,
  onExpandClick,
}) {
  const { isDarkMode } = useContext(ThemeContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Use the provided expanded state if available, otherwise manage it internally
  const [internalExpanded, setInternalExpanded] = useState(defaultExpanded);
  const isExpanded = expanded !== undefined ? expanded : internalExpanded;
  const handleExpandClick = onExpandClick || (() => setInternalExpanded(!internalExpanded));

  return (
    <Card
      sx={{
        ...components.card.root,
        opacity: isSelected ? 1 : 0.7,
        '&:hover': {
          ...components.card.root['&:hover'],
          opacity: 1,
        },
      }}
    >
      <CardHeader
        sx={{
          ...components.card.header,
          bgcolor: isExpanded && isSelected 
            ? theme.palette.mode === 'dark'
              ? 'rgba(255,255,255,0.05)'
              : tokens.colors.neutral[100]
            : 'transparent',
          transition: 'all 150ms ease',
          cursor: isSelected ? 'pointer' : 'default',
          '&:hover': isSelected ? {
            bgcolor: theme.palette.mode === 'dark'
              ? 'rgba(255,255,255,0.05)'
              : tokens.colors.neutral[100],
          } : {},
          // Make header padding responsive
          padding: isMobile ? `${tokens.spacing[2]} ${tokens.spacing[2]}` : `${tokens.spacing[3]} ${tokens.spacing[4]}`,
        }}
        onClick={isSelected ? handleExpandClick : undefined}
        title={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: tokens.spacing[2] }}>
            {isSelected && dragHandle && (
              <Box {...dragHandle} sx={{ 
                display: 'flex', 
                alignItems: 'center',
                cursor: 'grab',
                '&:hover': {
                  '& .MuiSvgIcon-root': {
                    color: tokens.colors.neutral[600],
                  }
                }
              }}>
                <DragIndicator 
                  fontSize="small" 
                  sx={{ 
                    color: tokens.colors.neutral[400],
                  }} 
                />
              </Box>
            )}
            <Typography 
              variant="subtitle1"
              color={theme.palette.mode === 'dark' ? 'text.primary' : 'text.secondary'}
              sx={{ 
                fontSize: tokens.typography.fontSizes.sm,
                fontWeight: tokens.typography.fontWeights.medium,
              }}
            >
              {title}
            </Typography>
          </Box>
        }
        action={
          isSelected ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {showRemoveButton && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemove();
                  }}
                  sx={{
                    color: tokens.colors.error,
                    '&:hover': {
                      backgroundColor: `${tokens.colors.error}10`,
                    },
                    // Increase touch target size for mobile
                    padding: isMobile ? tokens.spacing[1] : undefined,
                    minWidth: isMobile ? '48px' : undefined,
                    minHeight: isMobile ? '48px' : undefined,
                  }}
                >
                  <Remove fontSize="small" />
                </IconButton>
              )}
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleExpandClick();
                }}
                sx={{
                  color: tokens.colors.neutral[400],
                  '&:hover': {
                    color: tokens.colors.neutral[600],
                    backgroundColor: 'transparent',
                  },
                  // Increase touch target size for mobile
                  padding: isMobile ? tokens.spacing[1] : undefined,
                  minWidth: isMobile ? '48px' : undefined,
                  minHeight: isMobile ? '48px' : undefined,
                }}
              >
                {isExpanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
              </IconButton>
            </Box>
          ) : (
            <IconButton
              onClick={onSelect}
              sx={{
                color: tokens.colors.neutral[400],
                '&:hover': {
                  color: tokens.colors.neutral[600],
                  backgroundColor: 'transparent',
                },
                // Increase touch target size for mobile
                padding: isMobile ? tokens.spacing[1] : undefined,
                minWidth: isMobile ? '48px' : undefined,
                minHeight: isMobile ? '48px' : undefined,
              }}
            >
              <AddCircleOutline fontSize="small" />
            </IconButton>
          )
        }
      />
      {isSelected && (
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <CardContent sx={{
            ...components.card.content,
            pt: tokens.spacing[4],
            pb: tokens.spacing[2],
            // Make content padding responsive
            padding: isMobile ? tokens.spacing[2] : `${tokens.spacing[4]} ${tokens.spacing[4]} ${tokens.spacing[2]}`,
          }}>
            {children}
          </CardContent>
        </Collapse>
      )}
    </Card>
  );
}

export default BaseCard; 