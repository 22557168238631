import React, { useContext, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  AppBar,
  Toolbar,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FormContext } from '../context/FormContext';
import { PaginationContext } from '../context/PaginationContext';
import { useLayout } from '../context/LayoutContext';
import { useDesign } from '../context/DesignContext';
import { ThemeContext } from '../context/ThemeContext';
import { useTypography } from '../context/TypographyContext';
import { exportPdf } from '../services/PdfService';
import LightDarkMode from './PreviewSection/LightDarkMode';
import ExportButton from './ExportButton';
import { allSections } from '../components/InputSection/InputSection';
import tokens from '../theme/tokens';
import { ReactComponent as LogoIcon } from '../assets/logo_icon_dark.svg';

function Header({
  selectedSections,
  setSelectedSections,
  previewRef,
  setShowPreview,
  openTypographySettings
}) {
  const { formData, updateFormData } = useContext(FormContext);
  const { pages } = useContext(PaginationContext);
  const { isDarkMode } = useContext(ThemeContext);
  const { selectedLayout } = useLayout();
  const { selectedDesign } = useDesign();
  const { typographySettings } = useTypography();

  const [recoveryAnchorEl, setRecoveryAnchorEl] = useState(null);
  const [layoutDialogOpen, setLayoutDialogOpen] = useState(false);
  const [designDialogOpen, setDesignDialogOpen] = useState(false);

  const openRecoveryMenu = Boolean(recoveryAnchorEl);
  const fileInputRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const brandColor = isDarkMode ? '#FFFFFF' : tokens.colors.primary.main;

  const Logo = () => {
    return (
      <Box 
        sx={{ 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '32px',
          width: '32px',
          marginRight: '8px',
          color: brandColor,
          '& svg': {
            fill: 'currentColor',
            '& text': {
              fill: 'currentColor',
            },
            '& rect': {
              stroke: 'currentColor',
            }
          }
        }}
      >
        <LogoIcon />
      </Box>
    );
  };

  const handleRecoveryClick = (event) => {
    setRecoveryAnchorEl(event.currentTarget);
  };

  const handleRecoveryClose = () => {
    setRecoveryAnchorEl(null);
  };

  const handleExportPDF = async () => {
    await exportPdf(previewRef, setShowPreview);
  };

  const handleExport = () => {
    // Create export data with layout and design preferences
    const exportData = {
      formData,
      preferences: {
        layout: selectedLayout,
        design: selectedDesign,
        typography: typographySettings
      },
      selectedSections,
    };

    const dataStr = JSON.stringify(exportData, null, 2);
    const dataBlob = new Blob([dataStr], { type: 'application/json' });
    const url = URL.createObjectURL(dataBlob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'resume_data.json';
    document.body.appendChild(a);
    a.click();

    // Clean up
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleImport = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = JSON.parse(e.target.result);

        // Handle imported data
        if (data.formData) {
          updateFormData(null, data.formData);

          // Figure out which sections have data and should be selected
          const sectionsToActivate = [];

          // Check each section type
          if (data.formData.professionalSummary) {
            sectionsToActivate.push(allSections.find(s => s.name === 'Professional Summary'));
          }

          if (data.formData.workExperiences?.length > 0) {
            sectionsToActivate.push(allSections.find(s => s.name === 'Work Experience'));
          }

          if (data.formData.educations?.length > 0) {
            sectionsToActivate.push(allSections.find(s => s.name === 'Education'));
          }

          // Check all other section types...

          if (data.formData.skills?.length > 0) {
            sectionsToActivate.push(allSections.find(s => s.name === 'Skills'));
          }

          if (data.formData.certifications?.length > 0) {
            sectionsToActivate.push(allSections.find(s => s.name === 'Certifications'));
          }

          // ...etc for all section types

          // Activate sections with data
          if (sectionsToActivate.length > 0) {
            setSelectedSections(sectionsToActivate);
          }
        } else if (data.selectedSections) {
          // If the imported data explicitly includes selectedSections, use that
          setSelectedSections(data.selectedSections);
        }

        alert('Resume data imported successfully!');
      } catch (error) {
        console.error('Error importing data:', error);
        alert('Failed to import data. The file may be corrupted.');
      }

      // Reset the file input
      event.target.value = null;
    };

    reader.readAsText(file);
  };

  return (
    <AppBar
      position="static"
      elevation={1}
      sx={{
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
      }}
    >
      <Toolbar sx={{ 
        justifyContent: isMobile ? 'center' : 'space-between',
        padding: isMobile ? '0 8px' : undefined,
      }}>
        {/* Logo and title - centered on mobile */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          position: isMobile ? 'absolute' : 'static',
          left: isMobile ? '50%' : 'auto',
          transform: isMobile ? 'translateX(-50%)' : 'none',
        }}>
          <Typography 
            variant="h6" 
            sx={{ 
              color: brandColor,
              fontWeight: 'bold',
              // Show on both mobile and desktop
              display: 'block'
            }}
          >
            Priva
          </Typography>
          <Logo />
        </Box>

        {/* Right side - actions */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1,
          // For mobile, position on the right
          marginLeft: isMobile ? 'auto' : 0
        }}>
          {/* Desktop buttons */}
          {!isMobile && (
            <>
              <Button
                onClick={handleRecoveryClick}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{ mr: 1 }}
              >
                Recovery
              </Button>
              <Menu
                anchorEl={recoveryAnchorEl}
                open={openRecoveryMenu}
                onClose={handleRecoveryClose}
              >
                <MenuItem onClick={() => {
                  fileInputRef.current.click();
                  handleRecoveryClose();
                }}>
                  Upload Save File
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleExport();
                    handleRecoveryClose();
                  }}
                >
                  Download Save File
                </MenuItem>
              </Menu>
              <input
                type="file"
                accept="application/json"
                hidden
                onChange={handleImport}
                ref={fileInputRef}
              />

              {/* PDF export button */}
              <ExportButton
                previewRef={previewRef}
                setShowPreview={setShowPreview}
                variant="contained"
                color="secondary"
              />
            </>
          )}

          {/* Theme toggle */}
          <LightDarkMode />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header; 