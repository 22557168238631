// src/components/InputSection/cards/AwardsAndHonorsCard.js
import React from 'react';
import { Grid, TextField } from '@mui/material';
import { DateField, RichTextField } from '../shared/FormFields';
import SectionCardFactory from '../shared/SectionCardFactory';
import components from '../../../theme/components';

const AwardsAndHonorsCard = SectionCardFactory({
  sectionName: "Awards and Honors",
  sectionKey: "awardsAndHonors",
  
  initialItemFields: () => ({
    title: '',
    issuer: '',
    date: null,
    description: '',
    expanded: true,
  }),
  
  generateTitle: (item, index) => 
    item.title 
      ? `${item.title}${item.issuer ? ` from ${item.issuer}` : ''}`
      : `Award ${index + 1}`,
  
  renderFields: ({ item, handleChange }) => (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Award Title"
          value={item.title}
          onChange={(e) => handleChange('title', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Issuer"
          value={item.issuer}
          onChange={(e) => handleChange('issuer', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField
          label="Date"
          value={item.date}
          onChange={(date) => handleChange('date', date)}
        />
      </Grid>
      <Grid item xs={12}>
        <RichTextField
          value={item.description}
          onChange={(value) => handleChange('description', value)}
        />
      </Grid>
    </>
  )
});

export default AwardsAndHonorsCard;