// src/components/PublicationsCard.js
import React from 'react';
import { Grid, TextField } from '@mui/material';
import { DateField, RichTextField } from '../shared/FormFields';
import SectionCardFactory from '../shared/SectionCardFactory';
import components from '../../../theme/components';

const PublicationsCard = SectionCardFactory({
  sectionName: "Publications",
  sectionKey: "publications",
  
  initialItemFields: () => ({
    title: '',
    publisher: '',
    date: null,
    url: '',
    description: '',
    expanded: true,
  }),
  
  generateTitle: (item, index) => 
    item.title 
      ? `${item.title}${item.publisher ? ` - ${item.publisher}` : ''}`
      : `Publication ${index + 1}`,
  
  renderFields: ({ item, handleChange }) => (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Publication Title"
          value={item.title}
          onChange={(e) => handleChange('title', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Publisher"
          value={item.publisher}
          onChange={(e) => handleChange('publisher', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField
          label="Publication Date"
          value={item.date}
          onChange={(date) => handleChange('date', date)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="URL"
          value={item.url}
          onChange={(e) => handleChange('url', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12}>
        <RichTextField
          value={item.description}
          onChange={(value) => handleChange('description', value)}
        />
      </Grid>
    </>
  )
});

export default PublicationsCard;