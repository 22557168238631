import React, { createContext, useState, useContext, useEffect } from 'react';
import { useDesign } from './DesignContext';

const TypographyContext = createContext();

// Base default settings (used as a fallback)
const baseDefaultSettings = {
  heading: {
    fontSize: 28,
    fontFamily: 'Arial, sans-serif',
    lineHeight: 1.4,
  },
  subheading: {
    fontSize: 18,
    fontFamily: 'Arial, sans-serif',
    lineHeight: 1.3,
  },
  body: {
    fontSize: 11,
    fontFamily: 'Arial, sans-serif',
    lineHeight: 1.4,
  },
  small: {
    fontSize: 10,
    fontFamily: 'Arial, sans-serif',
    lineHeight: 1.3,
  },
  globalFontSize: 11,
  globalLineHeight: 1.4,
};

export function TypographyProvider({ children }) {
  const { activeDesign, selectedDesign } = useDesign();
  
  // Initialize typography settings based on design defaults or localStorage
  const [typographySettings, setTypographySettings] = useState(() => {
    // Get design-specific typography defaults
    const designDefaults = activeDesign?.typography || {};
    
    try {
      // Try to load saved settings from localStorage
      const savedSettings = localStorage.getItem('typographySettings');
      if (savedSettings) {
        const parsedSettings = JSON.parse(savedSettings);
        
        // Merge with base and design defaults
        return {
          ...baseDefaultSettings,
          ...designDefaults,
          ...parsedSettings,
          heading: {
            ...baseDefaultSettings.heading,
            ...(designDefaults.heading || {}),
            ...(parsedSettings.heading || {}),
          },
          subheading: {
            ...baseDefaultSettings.subheading,
            ...(designDefaults.subheading || {}),
            ...(parsedSettings.subheading || {}),
          },
          body: {
            ...baseDefaultSettings.body,
            ...(designDefaults.body || {}),
            ...(parsedSettings.body || {}),
          },
          small: {
            ...baseDefaultSettings.small,
            ...(designDefaults.small || {}),
            ...(parsedSettings.small || {}),
          },
        };
      }
      
      // No saved settings, use design defaults
      return {
        ...baseDefaultSettings,
        ...designDefaults,
        heading: {
          ...baseDefaultSettings.heading,
          ...(designDefaults.heading || {}),
        },
        subheading: {
          ...baseDefaultSettings.subheading,
          ...(designDefaults.subheading || {}),
        },
        body: {
          ...baseDefaultSettings.body,
          ...(designDefaults.body || {}),
        },
        small: {
          ...baseDefaultSettings.small,
          ...(designDefaults.small || {}),
        },
      };
    } catch (error) {
      console.error('Failed to load typography settings:', error);
      return {
        ...baseDefaultSettings,
        ...designDefaults,
      };
    }
  });

  // Update settings when design changes
  useEffect(() => {
    // Get design-specific typography defaults
    const designDefaults = activeDesign?.typography || {};
    
    // Update typography settings to match new design defaults while preserving user customizations
    setTypographySettings(current => ({
      ...baseDefaultSettings,
      ...designDefaults,
      ...current,
      heading: {
        ...baseDefaultSettings.heading,
        ...(designDefaults.heading || {}),
        ...current.heading,
      },
      subheading: {
        ...baseDefaultSettings.subheading,
        ...(designDefaults.subheading || {}),
        ...current.subheading,
      },
      body: {
        ...baseDefaultSettings.body,
        ...(designDefaults.body || {}),
        ...current.body,
      },
      small: {
        ...baseDefaultSettings.small,
        ...(designDefaults.small || {}),
        ...current.small,
      },
    }));
  }, [selectedDesign, activeDesign]);

  // Function to reset typography to design defaults
  const resetToDesignDefaults = () => {
    const designDefaults = activeDesign?.typography || {};
    
    setTypographySettings({
      ...baseDefaultSettings,
      ...designDefaults,
      heading: {
        ...baseDefaultSettings.heading,
        ...(designDefaults.heading || {}),
      },
      subheading: {
        ...baseDefaultSettings.subheading,
        ...(designDefaults.subheading || {}),
      },
      body: {
        ...baseDefaultSettings.body,
        ...(designDefaults.body || {}),
      },
      small: {
        ...baseDefaultSettings.small,
        ...(designDefaults.small || {}),
      },
    });
  };

  const updateTypographySettings = (newSettings) => {
    try {
      // Ensure all required properties exist by merging with current settings
      const updatedSettings = {
        ...typographySettings,
        ...newSettings,
        heading: {
          ...typographySettings.heading,
          ...(newSettings.heading || {}),
        },
        subheading: {
          ...typographySettings.subheading,
          ...(newSettings.subheading || {}),
        },
        body: {
          ...typographySettings.body,
          ...(newSettings.body || {}),
        },
        small: {
          ...typographySettings.small,
          ...(newSettings.small || {}),
        },
      };
      
      setTypographySettings(updatedSettings);
      
      // Save to localStorage
      localStorage.setItem('typographySettings', JSON.stringify(updatedSettings));
    } catch (error) {
      console.error('Error updating typography settings:', error);
    }
  };

  // Add this near the top of the TypographyProvider function
  useEffect(() => {
    // Check for imported typography settings on mount
    try {
      const importedSettings = localStorage.getItem('importedTypographySettings');
      if (importedSettings) {
        const parsedSettings = JSON.parse(importedSettings);
        updateTypographySettings(parsedSettings);
        // Clear the imported settings to prevent reapplying on refresh
        localStorage.removeItem('importedTypographySettings');
      }
    } catch (error) {
      console.error('Failed to apply imported typography settings:', error);
    }
  }, []);

  // Also listen for typography import events
  useEffect(() => {
    const handleImport = () => {
      try {
        console.log('Typography import event received');
        const importedSettings = localStorage.getItem('importedTypographySettings');
        if (importedSettings) {
          const parsedSettings = JSON.parse(importedSettings);
          console.log('Imported typography settings:', parsedSettings);
          
          // Create a complete settings object with all required fields
          const completeSettings = {
            ...baseDefaultSettings,
            ...parsedSettings,
            heading: {
              ...baseDefaultSettings.heading,
              ...(parsedSettings.heading || {})
            },
            subheading: {
              ...baseDefaultSettings.subheading,
              ...(parsedSettings.subheading || {})
            },
            body: {
              ...baseDefaultSettings.body,
              ...(parsedSettings.body || {})
            },
            small: {
              ...baseDefaultSettings.small,
              ...(parsedSettings.small || {})
            }
          };
          
          // Set the state directly first for immediate update
          setTypographySettings(completeSettings);
          
          // Then update localStorage
          localStorage.setItem('typographySettings', JSON.stringify(completeSettings));
          
          // Clear the imported settings
          localStorage.removeItem('importedTypographySettings');
          
          console.log('Typography settings updated from import');
        }
      } catch (error) {
        console.error('Failed to apply imported typography settings from event:', error);
      }
    };
    
    // Add the event listener
    window.addEventListener('typographySettingsImported', handleImport);
    
    // Clean up
    return () => {
      window.removeEventListener('typographySettingsImported', handleImport);
    };
  }, []); // Remove updateTypographySettings from dependencies to avoid recreating listener

  return (
    <TypographyContext.Provider value={{ 
      typographySettings, 
      updateTypographySettings,
      resetToDesignDefaults
    }}>
      {children}
    </TypographyContext.Provider>
  );
}

export function useTypography() {
  const context = useContext(TypographyContext);
  if (!context) {
    throw new Error('useTypography must be used within a TypographyProvider');
  }
  return context;
} 