// src/components/WorkExperienceCard.js

import React from 'react';
import { Grid, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { DateField, RichTextField } from '../shared/FormFields';
import SectionCardFactory from '../shared/SectionCardFactory';
import components from '../../../theme/components';
import tokens from '../../../theme/tokens';

const WorkExperienceCard = SectionCardFactory({
  sectionName: "Work Experience",
  sectionKey: "workExperiences",
  
  initialItemFields: () => ({
    company: '',
    position: '',
    location: '',
    startDate: null,
    endDate: null,
    current: false,
    description: '',
    expanded: true,
  }),
  
  generateTitle: (item, index) => 
    item.company 
      ? `${item.company}${item.position ? ` - ${item.position}` : ''}`
      : `Experience ${index + 1}`,
  
  renderFields: ({ item, handleChange }) => (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Company"
          value={item.company}
          onChange={(e) => handleChange('company', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Position"
          value={item.position}
          onChange={(e) => handleChange('position', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Location"
          value={item.location}
          onChange={(e) => handleChange('location', e.target.value)}
          sx={components.input.root}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField
          label="Start Date"
          value={item.startDate}
          onChange={(date) => handleChange('startDate', date)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField
          label="End Date"
          value={item.endDate}
          onChange={(date) => handleChange('endDate', date)}
          disabled={item.current}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={item.current}
              onChange={(e) => handleChange('current', e.target.checked)}
              sx={components.checkbox}
            />
          }
          label="Current Position"
          sx={{
            color: tokens.colors.neutral[700],
            '.MuiFormControlLabel-label': {
              fontSize: tokens.typography.fontSizes.sm,
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <RichTextField
          value={item.description}
          onChange={(value) => handleChange('description', value)}
        />
      </Grid>
    </>
  )
});

export default WorkExperienceCard;
