import React from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  useTheme, 
  useMediaQuery,
  Grid,
} from '@mui/material';
import { useDesign } from '../context/DesignContext';

// Color Donut Component - A visual circular representation of the color scheme
const ColorDonut = ({ colors, size = 80 }) => {
  // Extract main colors from the design
  const mainColors = [
    colors.primary,
    colors.secondary,
    colors.tertiary,
    colors.quaternary,
    colors.accent1
  ];
  
  // Calculate sizes for donut
  const centerSize = size * 0.3; // Size of inner circle
  const strokeWidth = (size - centerSize) / 2;
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  
  // Each color gets an equal arc
  const arcLength = circumference / mainColors.length;
  
  return (
    <Box sx={{ 
      width: size, 
      height: size, 
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        {mainColors.map((color, index) => {
          // Calculate start and end positions for each arc
          const startPercent = index / mainColors.length;
          const endPercent = (index + 1) / mainColors.length;
          
          const x1 = size/2 + radius * Math.cos(2 * Math.PI * startPercent - Math.PI/2);
          const y1 = size/2 + radius * Math.sin(2 * Math.PI * startPercent - Math.PI/2);
          const x2 = size/2 + radius * Math.cos(2 * Math.PI * endPercent - Math.PI/2);
          const y2 = size/2 + radius * Math.sin(2 * Math.PI * endPercent - Math.PI/2);
          
          // Determine if we need to use the large arc flag (1) or not (0)
          const largeArcFlag = endPercent - startPercent > 0.5 ? 1 : 0;
          
          return (
            <path
              key={index}
              d={`
                M ${x1} ${y1}
                A ${radius} ${radius} 0 ${largeArcFlag} 1 ${x2} ${y2}
              `}
              stroke={color}
              strokeWidth={strokeWidth}
              fill="none"
            />
          );
        })}
        
        {/* Inner circle with background color */}
        <circle 
          cx={size/2} 
          cy={size/2} 
          r={centerSize/2} 
          fill={colors.background || '#ffffff'} 
          stroke={colors.border || '#e0e0e0'}
          strokeWidth="1"
        />
      </svg>
      
      {/* Text in the center showing design style */}
      <Typography 
        sx={{ 
          position: 'absolute', 
          fontSize: '10px',
          fontWeight: 'bold',
          textAlign: 'center',
          color: colors.text || '#000000'
        }}
      >
        {colors.id?.charAt(0).toUpperCase() || 'T'}
      </Typography>
    </Box>
  );
};

function DesignSelector() {
  const { designs, selectedDesign, setSelectedDesign } = useDesign();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDesignSelect = (designId) => {
    setSelectedDesign(designId);
  };

  // Design selection content - just return the content directly
  return (
    <Box>
      <Grid container spacing={2}>
        {designs.map((design) => (
          <Grid item xs={6} sm={4} key={design.id}>
            <Paper
              elevation={selectedDesign === design.id ? 3 : 1}
              onClick={() => handleDesignSelect(design.id)}
              sx={{
                padding: '12px',
                cursor: 'pointer',
                transition: 'all 0.3s ease-in-out',
                border: selectedDesign === design.id 
                  ? `2px solid ${theme.palette.primary.main}` 
                  : `1px solid ${theme.palette.divider}`,
                height: '140px',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.palette.background.paper,
                position: 'relative',
                borderRadius: '8px',
                zIndex: 1,
                overflow: 'hidden',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: theme.shadows[4],
                  zIndex: 2,
                  height: 'auto',
                  maxHeight: '250px', // Limit maximum expansion
                  overflow: 'visible',
                  '& .design-content': {
                    maxHeight: '150px',
                    opacity: 1,
                  }
                },
              }}
            >
              {/* Color Donut */}
              <Box sx={{ 
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: 1,
              }}>
                <ColorDonut colors={design.colors} size={80} />
              </Box>
              
              {/* Design Name and Description */}
              <Box 
                className="design-content"
                sx={{
                  textAlign: 'center',
                  mt: 1,
                  transition: 'all 0.3s ease-in-out',
                  maxHeight: '40px', // Limit height initially
                  overflow: 'hidden',
                  opacity: 0.9,
                }}
              >
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    fontWeight: 'medium',
                    fontSize: '0.9rem',
                  }}
                >
                  {design.name}
                </Typography>
                
                <Typography 
                  variant="caption" 
                  sx={{ 
                    color: theme.palette.text.secondary,
                    fontSize: '0.7rem',
                    display: 'block',
                    marginTop: '4px',
                    padding: '0 4px',
                  }}
                >
                  {design.description}
                </Typography>
                
                {/* Extra details that show on hover */}
                <Box 
                  sx={{ 
                    mt: 1,
                    pt: 1,
                    borderTop: `1px dashed ${theme.palette.divider}`,
                  }}
                >
                  <Typography 
                    variant="caption" 
                    sx={{ 
                      color: theme.palette.text.secondary,
                      fontSize: '0.65rem',
                      display: 'block',
                    }}
                  >
                    Primary: {design.colors.primary}
                  </Typography>
                  <Typography 
                    variant="caption" 
                    sx={{ 
                      color: theme.palette.text.secondary,
                      fontSize: '0.65rem',
                      display: 'block',
                    }}
                  >
                    Secondary: {design.colors.secondary}
                  </Typography>
                  <Typography 
                    variant="caption" 
                    sx={{ 
                      color: theme.palette.text.secondary,
                      fontSize: '0.65rem',
                      display: 'block',
                    }}
                  >
                    Font: {design.typography?.body?.fontFamily?.split(',')[0].replace(/['"`]/g, '')}
                  </Typography>
                </Box>
              </Box>
              
              {/* Selected Indicator */}
              {selectedDesign === design.id && (
                <Box sx={{
                  position: 'absolute',
                  top: 5,
                  right: 5,
                  width: 16,
                  height: 16,
                  borderRadius: '50%',
                  backgroundColor: theme.palette.primary.main,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: theme.palette.primary.contrastText,
                  fontSize: 12,
                  fontWeight: 'bold',
                  zIndex: 2,
                }}>
                  ✓
                </Box>
              )}
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default DesignSelector; 