import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import ReactDOM from 'react-dom/client';
import React from 'react';
import { LayoutProvider } from '../context/LayoutContext';
import { DesignProvider } from '../context/DesignContext';
import { TypographyProvider } from '../context/TypographyContext';
import LayoutResolver from '../layouts/LayoutResolver';

// Service to generate and export PDFs
class PdfService {
  constructor() {
    this.pdfContainer = null;
    this.initContainer();
  }

  // Initialize hidden container for PDF rendering
  initContainer() {
    if (!this.pdfContainer) {
      this.pdfContainer = document.createElement('div');
      this.pdfContainer.style.position = 'absolute';
      this.pdfContainer.style.left = '-9999px';
      this.pdfContainer.style.width = '210mm';  // A4 width
      this.pdfContainer.style.minHeight = '297mm'; // A4 height
      document.body.appendChild(this.pdfContainer);
    }
  }

  // Generate PDF with multiple pages
  async generatePdf(formData, pages, selectedLayout, activeDesign, typographySettings) {
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
      compress: true
    });

    try {
      // For each page, render the content and add to PDF
      for (let i = 0; i < pages.length; i++) {
        if (i > 0) {
          pdf.addPage();
        }
        
        // Render current page
        const pageElement = await this.renderPage(
          formData, 
          pages[i], 
          i, 
          pages.length, 
          selectedLayout, 
          activeDesign, 
          typographySettings
        );
        
        // Convert to canvas and add to PDF
        const canvas = await this.capturePageToCanvas(pageElement);
        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        
        pdf.addImage(imgData, 'JPEG', 0, 0, pageWidth, pageHeight, '', 'FAST');
        
        // Clean up
        this.pdfContainer.removeChild(pageElement);
      }
      
      return pdf;
    } catch (error) {
      console.error('Error generating PDF:', error);
      throw error;
    }
  }

  // Render a single page using our layout system
  async renderPage(formData, pageContent, pageNum, totalPages, selectedLayout, activeDesign, typographySettings) {
    return new Promise((resolve) => {
      // Create a page element
      const pageElement = document.createElement('div');
      pageElement.style.width = '210mm';
      pageElement.style.height = '297mm';
      pageElement.style.margin = '0';
      pageElement.style.padding = '0';
      pageElement.style.backgroundColor = 'white';
      pageElement.style.overflow = 'hidden';
      pageElement.style.fontFamily = 'Arial, sans-serif';

      // Create root element for React
      const root = document.createElement('div');
      pageElement.appendChild(root);
      this.pdfContainer.appendChild(pageElement);

      // Initialize React root
      requestAnimationFrame(() => {
        const reactRoot = ReactDOM.createRoot(root);
        
        // Wrap in context providers to ensure layout works correctly
        reactRoot.render(
          <React.StrictMode>
            <LayoutProvider initialLayout={selectedLayout}>
              <DesignProvider initialDesign={activeDesign?.id || 'blue'}>
                <TypographyProvider initialSettings={typographySettings}>
                  <LayoutResolver
                    formData={formData}
                    selectedSections={pageContent}
                    currentPage={pageNum}
                    totalPages={totalPages}
                    isPdfExport={true}
                  />
                </TypographyProvider>
              </DesignProvider>
            </LayoutProvider>
          </React.StrictMode>
        );
        
        // Wait for rendering to complete
        setTimeout(() => {
          resolve(pageElement);
        }, 500);
      });
    });
  }

  // Convert page element to canvas
  async capturePageToCanvas(pageElement) {
    return html2canvas(pageElement, {
      scale: 2, // Higher scale for better quality
      useCORS: true,
      allowTaint: true,
      logging: false,
      backgroundColor: '#ffffff'
    });
  }
}

// Function to export PDF directly from components
export const exportPdf = async (previewRef, setShowPreview) => {
  return new Promise(async (resolve) => {
    try {
      console.log('Starting PDF export...');
      
      // Show full preview if needed
      if (setShowPreview) {
        setShowPreview(true);
        // Give time for the preview to render
        await new Promise(r => setTimeout(r, 500));
      }
      
      // Find the A4 container element directly
      let a4Container = null;
      
      if (previewRef && previewRef.current) {
        a4Container = previewRef.current.querySelector('#pdf-content');
        
        if (!a4Container) {
          console.error('Could not find A4 container with ID pdf-content');
          alert('Unable to find resume content. Please try again.');
          resolve(false);
          return;
        }
      }
      
      // Show loading indicator
      const loadingElement = document.createElement('div');
      loadingElement.style.position = 'fixed';
      loadingElement.style.top = '50%';
      loadingElement.style.left = '50%';
      loadingElement.style.transform = 'translate(-50%, -50%)';
      loadingElement.style.padding = '20px';
      loadingElement.style.background = 'rgba(0,0,0,0.7)';
      loadingElement.style.color = 'white';
      loadingElement.style.borderRadius = '8px';
      loadingElement.style.zIndex = '9999';
      loadingElement.textContent = 'Generating PDF, please wait...';
      document.body.appendChild(loadingElement);
      
      // Store original styles to restore later
      const originalStyles = {
        transform: a4Container.style.transform,
        boxShadow: a4Container.style.boxShadow,
        margin: a4Container.style.margin,
        position: a4Container.style.position,
      };
      
      // Apply clean styles for export
      a4Container.style.transform = 'none';
      a4Container.style.boxShadow = 'none';
      a4Container.style.margin = '0';
      a4Container.style.position = 'relative';
      
      try {
        // Create jsPDF instance first
        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'mm',
          format: 'a4',
          compress: true
        });
        
        // Use html2canvas with different settings than before
        const canvas = await html2canvas(a4Container, {
          scale: 2,  // Higher quality but not too high
          useCORS: true,
          logging: false,
          allowTaint: true,
          backgroundColor: '#ffffff',
          onclone: (clonedDoc) => {
            // Further clean the cloned document
            const clonedElement = clonedDoc.querySelector('#pdf-content');
            if (clonedElement) {
              clonedElement.style.transform = 'none';
              clonedElement.style.boxShadow = 'none';
              clonedElement.style.border = 'none';
              clonedElement.style.borderRadius = '0';
            }
          }
        });
        
        // Add the image to PDF - ensuring it fits the A4 page exactly
        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297);
        
        // Generate filename with date
        const now = new Date();
        const dateStr = `${now.getFullYear()}-${(now.getMonth()+1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
        const filename = `PrivaCV_Resume_${dateStr}.pdf`;
        
        pdf.save(filename);
        
        // Success!
        resolve(true);
      } catch (error) {
        console.error('Error in PDF generation:', error);
        alert('PDF generation failed. Please try again.');
        resolve(false);
      } finally {
        // Restore original styles
        a4Container.style.transform = originalStyles.transform;
        a4Container.style.boxShadow = originalStyles.boxShadow;
        a4Container.style.margin = originalStyles.margin;
        a4Container.style.position = originalStyles.position;
      }
      
      // Remove loading indicator
      document.body.removeChild(loadingElement);
      
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('There was an error generating the PDF: ' + error.message);
      resolve(false);
    }
  });
};

// Export service instance
const pdfService = new PdfService();
export default pdfService;