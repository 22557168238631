import React from 'react';
import { TextField, Grid, useMediaQuery } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import ReactQuill from 'react-quill';
import tokens from '../../../theme/tokens';
import components from '../../../theme/components';
import 'react-quill/dist/quill.bubble.css';
import { styled, useTheme } from '@mui/material/styles';

export const DateField = ({ label, value, onChange, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <DatePicker
      label={label}
      views={['year', 'month']}
      value={value}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          sx={{
            ...components.input.root,
            '& .MuiInputBase-root': {
              height: tokens.spacing.input.height,
              minHeight: tokens.spacing.input.height,
              display: 'flex',
              alignItems: 'center',
            },
            '& .MuiOutlinedInput-input': {
              padding: tokens.spacing.input.padding,
              height: 'auto',
            },
            '& .MuiInputLabel-root': {
              transform: 'translate(14px, 16px) scale(1)',
              '&.MuiInputLabel-shrink': {
                transform: 'translate(14px, -6px) scale(0.75)',
              }
            }
          }}
          inputProps={{
            ...params.inputProps,
            readOnly: true,
            style: {
              fontSize: tokens.typography.fontSizes.xs,
              padding: 0,
            }
          }}
        />
      )}
      {...props}
    />
  );
};

const StyledQuillWrapper = styled('div')(({ theme, isMobile }) => (`
  .ql-container {
    min-height: ${isMobile ? '100px' : '150px'};
    border-radius: ${tokens.radius.base};
    border-color: ${theme.palette.mode === 'dark' 
      ? tokens.colors.borders.dark 
      : tokens.colors.borders.light};
  }
  .ql-toolbar {
    border-radius: ${tokens.radius.base};
    background-color: ${theme.palette.mode === 'dark' 
      ? tokens.colors.background.dark 
      : tokens.colors.background.light};
    border-color: ${theme.palette.mode === 'dark' 
      ? tokens.colors.borders.dark 
      : tokens.colors.borders.light};
    ${isMobile ? `
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 4px;
    ` : ''}
  }
  .ql-editor {
    min-height: ${isMobile ? '100px' : '150px'};
    font-family: ${tokens.typography.fontFamily.primary};
    font-size: ${isMobile ? tokens.typography.fontSizes.base : tokens.typography.fontSizes.sm};
    color: ${theme.palette.mode === 'dark' 
      ? tokens.colors.text.dark
      : tokens.colors.text.light};
    background-color: ${theme.palette.mode === 'dark' 
      ? tokens.colors.background.dark 
      : tokens.colors.background.light};
  }
  ${isMobile ? `
    .ql-toolbar button {
      width: 28px;
      height: 28px;
      padding: 2px;
    }
  ` : ''}
`));

export const RichTextField = ({ value, onChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <StyledQuillWrapper className="rich-text-editor" isMobile={isMobile}>
      <ReactQuill
        value={value || ''}
        onChange={onChange}
        modules={{
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ color: [] }, { background: [] }],
            ['clean']
          ],
        }}
        theme="bubble"
        style={{
          height: isMobile ? '100px' : '150px',
          marginBottom: isMobile ? tokens.spacing[6] : tokens.spacing[10],
          backgroundColor: theme.palette.mode === 'dark' 
            ? tokens.colors.background.dark 
            : tokens.colors.background.default,
        }}
      />
    </StyledQuillWrapper>
  );
};

export const StyledTextField = styled(TextField)(({ theme, isMobile }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? tokens.colors.background.dark 
      : tokens.colors.background.light,
    height: 'auto',
    minHeight: isMobile ? '48px' : tokens.spacing.input.height,
    display: 'flex',
    alignItems: 'center',
    marginBottom: tokens.spacing.input.marginBottom,
    '& fieldset': {
      borderColor: theme.palette.mode === 'dark'
        ? tokens.colors.borders.dark
        : tokens.colors.borders.light,
    },
    '& input': {
      fontSize: isMobile ? tokens.typography.fontSizes.base : tokens.typography.fontSizes.xs,
      padding: isMobile ? `8px 12px` : undefined,
      height: 'auto',
    }
  },
  '& .MuiInputLabel-root': {
    fontSize: isMobile ? tokens.typography.fontSizes.sm : tokens.typography.fontSizes.xs,
    transform: isMobile ? 'translate(12px, 14px) scale(1)' : undefined,
    '&.MuiInputLabel-shrink': {
      transform: isMobile ? 'translate(12px, -6px) scale(0.75)' : undefined,
    }
  }
}));

export const FormGridContainer = styled(Grid)(({ theme, isMobile }) => ({
  '& .MuiGrid-item': {
    paddingTop: `${isMobile ? tokens.spacing[1] : tokens.spacing.input.gap} !important`,
  },
  '& .MuiTextField-root': {
    marginBottom: isMobile ? tokens.spacing[2] : tokens.spacing.input.marginBottom,
  },
  '& .rich-text-editor': {
    marginBottom: isMobile ? tokens.spacing[2] : tokens.spacing.input.marginBottom,
  }
}));

// Enhanced version with mobile support
export const ResponsiveTextField = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <TextField
      {...props}
      fullWidth
      sx={{
        ...components.input.root,
        '& .MuiInputBase-root': {
          height: 'auto',
          minHeight: isMobile ? '48px' : tokens.spacing.input.height,
          display: 'flex',
          alignItems: 'center',
        },
        '& .MuiOutlinedInput-input': {
          padding: isMobile ? `8px 12px` : tokens.spacing.input.padding,
          fontSize: isMobile ? tokens.typography.fontSizes.base : tokens.typography.fontSizes.xs,
          height: 'auto',
        },
        '& .MuiInputLabel-root': {
          fontSize: isMobile ? tokens.typography.fontSizes.sm : tokens.typography.fontSizes.xs,
          transform: isMobile ? 'translate(12px, 14px) scale(1)' : undefined,
          '&.MuiInputLabel-shrink': {
            transform: isMobile ? 'translate(12px, -6px) scale(0.75)' : undefined,
          }
        },
        ...(props.sx || {}),
      }}
    />
  );
};